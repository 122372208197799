<template>
 <v-select :id="id" v-model="selectedCustomer" :disabled="disabled" :options="formattedCustomers" label="name">
     <div slot="no-options">{{ $t('customerSelect.notFound') }}</div>
 </v-select>
</template>

<script>
import axios from "axios";

export default {
    name: "CustomerSelector",
    props: {
        value: {
            validator: prop => typeof prop === 'number' || prop === null,
            default: null
        },
        id: {
            type: String
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            customers: []
        }
    },
    computed: {
        selectedCustomer: {
            get() {
                let customer = this.formattedCustomers.find(customer => customer.id === this.value);
                if (typeof customer === 'undefined') {
                    return null;
                }
                return customer;
            },
            set(customer) {
                this.$emit('input', customer.id);
            }
        },
        formattedCustomers() {
            return this.customers.map(function (customer) {
                return {
                    id: customer.id,
                    name: customer.company + ' (' + customer.number + ')'
                }
            })
        }
    },
    created() {
        let sToken = this.$store.state.sessionToken;
        axios.get(`/api/customers?session=${sToken}`)
            .then(response => (this.customers = response.data))
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    this.$store.dispatch('logout', {name: this.$route.name});
                }
            });
    }
}
</script>

<style lang="scss">
$vs-colors: (
    lightest: rgba(60, 60, 60, 0.26),
    light: rgba(60, 60, 60, 0.5),
    dark: #6a6a6a,
    darkest: #6a6a6a,
) !default;
$vs-component-line-height: 1.1875rem;
$vs-state-active-bg: $button-primary-bg-color;
$vs-border-radius: 0;
$vs-border-color: $content-box-border-color;

@import "~vue-select/src/scss/vue-select";

.v-select {
    width: 100%;

    & .vs__dropdown-toggle {
        height: 2.6rem;
    }
}
</style>