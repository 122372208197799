const accents      = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
const replacements = "aaaaeeeeiiiioooouuuunc------";

export default function slugifyGroupName(name) {
    let slug = name.replace(/^\s+|\s+$/g, ''); // trim
    slug = slug.toLowerCase();

    // remove accents, swap ñ for n, etc
    for (let i=0, l=accents.length ; i<l ; i++) {
        slug = slug.replace(new RegExp(accents.charAt(i), 'g'), replacements.charAt(i));
    }

    slug = slug.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return slug;
}