<template>
    <div class="overview-wrapper">
        <h2>{{ $t('orderSeason.title') }}</h2>
        <fieldset>
            <filter-input v-model="searchText" :placeholder="$t('orderSeason.filterOverview')"></filter-input>
        </fieldset>
        <div class="table-wrapper">
        <table>
            <thead>
            <tr>
                <sortable-header-cell column="name" v-model="sort">
                    {{ $t('orderSeason.name') }}
                </sortable-header-cell>
                <sortable-header-cell column="lastModified" v-model="sort">
                    {{ $t('orderSeason.modified') }}
                </sortable-header-cell>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr class="loading" v-if="loading">
                <td colspan="2"><loading-spinner></loading-spinner></td>
            </tr>
            <tr v-for="season in seasons" :key="season.id">
                <td>
                    <router-link :to="{ name: 'edit-season', params: {seasonId: season.id}}">
                        {{ season.name }}
                    </router-link>
                </td>
                <td>
                    {{ formatDate(season.lastModified) }}
                </td>
                <td class="actions" @click="deleteSeason(season.id)">
                    <button class="slim">
                        <fa-icon icon="trash-alt"></fa-icon>
                    </button>
                </td>
            </tr>
            </tbody>
        </table>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import LoadingSpinner from "@/FrontEnd/components/LoadingSpinner";
import FilterInput from "@/FrontEnd/components/FilterInput";
import SortableHeaderCell from "@/FrontEnd/components/SortableHeaderCell";
import EventBus from "@/FrontEnd/eventbus";

export default {
    name: "OrderSeasonList",
    components: {SortableHeaderCell, FilterInput, LoadingSpinner},
    data () {
        return {
            seasons: [],
            loading: false,
            searchText: '',
            sort: {
                sortColumn: 'lastModified',
                sortDir: 'desc'
            }
        };
    },
    created() {
        this.refresh();
    },
    methods: {
        refresh() {
            let sToken = this.$store.state.sessionToken;
            this.seasons = [];
            this.loading = true;
            axios.get(`/api/orderSeason?session=${sToken}`)
                .then(response => (this.seasons = response.data))
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        this.$store.dispatch('logout', {name: this.$route.name});
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        formatDate(date) {
            date = Date.parse(date);
            return new Intl.DateTimeFormat('de-DE', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: 'numeric',
                minute: 'numeric',
            }).format(date);
        },
        deleteSeason(seasonId) {
            EventBus.$emit(
                'confirm-requested',
                this.$t('orderSeason.deleteConfirm'),
                () => {
                    let sToken = this.$store.state.sessionToken;
                    axios.delete(`/api/orderSeason/${seasonId}?session=${sToken}`)
                        .then(() => this.refresh())
                        .catch(error => {
                            if (error.response && error.response.status === 401) {
                                this.$store.dispatch('logout', {name: this.$route.name});
                            }
                        });
                }
            );
        }
    }
}
</script>

<style scoped lang="scss">
    @import "@/FrontEnd/styles/overview";
</style>