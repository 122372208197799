<template>
    <div class="page-wrapper">
        <main-navigation class="main-nav"></main-navigation>
        <div class="content-wrapper"><slot></slot></div>
    </div>
</template>

<script>
import MainNavigation from "@/FrontEnd/components/MainNavigation";
export default {
    name: "PageWithNavigation",
    components: {MainNavigation}
}
</script>

<style scoped>
    div.page-wrapper {
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }
    div.content-wrapper {
        flex: 1;
        overflow: auto;
    }
</style>