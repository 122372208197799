<template>
    <div class="base-discount-wrapper">
        <div>
            <label>{{ $t('baseDiscount.activeLabel') }}</label>
            <input type="checkbox" v-model="active">
        </div>
        <fieldset v-if="active">
            <div>
                <label>{{ $t('baseDiscount.conditionLabel') }}</label>
                <input type="number" v-model="baseDiscount.min" class="slim">
            </div>
            <div>
                <label>{{ $t('baseDiscount.percentLabel') }}</label>
                <input type="number" v-model="baseDiscount.discount" class="slim">
            </div>
        </fieldset>
    </div>
</template>

<script>
export default {
    name: "BaseDiscount",
    props: {
        value: {
            type: Object,
            default: null
        }
    },
    computed: {
        active: {
            get() {
                return this.value !== null;
            },
            set(val) {
                if (val) {
                    this.$emit('input', {min:1000, discount:5});
                    return;
                }
                this.$emit('input', null);
            }
        },
        baseDiscount: {
            get() {
                return this.value;
            },
            set(newVal) {
                this.$emit('input', newVal);
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import "@/FrontEnd/styles/input";
@import "@/FrontEnd/styles/layout";
.base-discount-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    & fieldset {
        border: none;
        width: 100%;
        &>* {
            display: flex;
            flex-direction: row;
            align-items: center;

            &>label {
                max-width: 20rem;
                width: 50%;
            }
        }
    }
}
</style>