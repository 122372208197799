<template>
    <div class="overview-wrapper">
        <h2>{{ $t('orderRecOverview.sentTitle') }}</h2>
        <fieldset>
            <filter-input v-model="searchText" :placeholder="$t('orderRecOverview.filterOffers')"></filter-input>
        </fieldset>
        <div class="table-wrapper">
        <table>
            <thead>
            <tr>
                <sortable-header-cell column="customer.number" v-model="sort">
                    {{ $t('orderRecOverview.customerNumber') }}
                </sortable-header-cell>
                <sortable-header-cell column="customer.company" v-model="sort">
                    {{ $t('orderRecOverview.customer') }}
                </sortable-header-cell>
                <sortable-header-cell column="name" v-model="sort">
                    {{ $t('orderRecOverview.sentName') }}
                </sortable-header-cell>
                <sortable-header-cell column="sent" v-model="sort" default-sort-dir="desc">
                    {{ $t('orderRecOverview.sentDate') }}
                </sortable-header-cell>
                <sortable-header-cell column="lastModified" v-model="sort" default-sort-dir="desc">
                    {{ $t('orderRecOverview.modified') }}
                </sortable-header-cell>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr class="loading" v-if="loading">
                <td colspan="5"><loading-spinner></loading-spinner></td>
            </tr>
            <tr class="empty-state" v-if="!loading && filteredOffers.length < 1 && searchText">
                <td colspan="5">{{ $t('article.emptyFilterResult') }}</td>
            </tr>
            <tr
                v-for="offer in filteredOffers"
                :key="offer.id"
                class="data"
            >
                <td class="cell-action" @click="openEditor(offer.id)">{{ offer.customer ? offer.customer.number : '' }}</td>
                <td class="cell-action" @click="openEditor(offer.id)">{{ offer.customer ? offer.customer.company : '' }}</td>
                <td class="cell-action" @click.capture="openEditor(offer.id)">
                    <router-link :to="{ name:'edit-recommendation', params: {offerId: offer.id} }">
                        {{ offer.name }}
                    </router-link>
                </td>
                <td class="cell-action" @click.capture="openEditor(offer.id)">{{ formatDate(offer.sent) }}</td>
                <td class="cell-action" @click.capture="openEditor(offer.id)">{{ formatDate(offer.lastModified) }}</td>
                <td class="actions">
                    <button class="slim" @click="() => deleteRecommendation(offer.id, offer.name)">
                        <fa-icon icon="trash-alt"></fa-icon>
                    </button>
                </td>
            </tr>
            </tbody>
        </table>
        </div>
    </div>
</template>

<script>
import LoadingSpinner from "@/FrontEnd/components/LoadingSpinner";
import axios from "axios";
import FilterInput from "@/FrontEnd/components/FilterInput";
import SortableHeaderCell from "@/FrontEnd/components/SortableHeaderCell";
import EventBus from "@/FrontEnd/eventbus";
export default {
    name: "SentRecommendationsList",
    components: {SortableHeaderCell, FilterInput, LoadingSpinner},
    data() {
        return {
            sentOffers: [],
            loading: false,
            searchText: '',
            sort: {
                sortColumn: 'lastModified',
                sortDir: 'desc'
            }
        }
    },
    created() {
        this.refreshList();
    },
    methods: {
        refreshList() {
            this.sentOffers = [];
            let sToken = this.$store.state.sessionToken;
            this.loading = true;
            axios.get(`/api/orderRecommendation/sent?session=${sToken}`)
                .then(response => (this.sentOffers = response.data))
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        this.$store.dispatch('logout', {name: this.$route.name});
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        formatDate(date) {
            date = Date.parse(date);
            return new Intl.DateTimeFormat('de-DE', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: 'numeric',
                minute: 'numeric',
            }).format(date);
        },
        openEditor(recId) {
            this.$router.push({ name:'edit-recommendation', params: {offerId: recId} });
        },
        deleteRecommendation(recId, name) {
            EventBus.$emit(
                'confirm-requested',
                this.$t('orderRecOverview.deleteConfirm', {name}),
                () => {
                    let sToken = this.$store.state.sessionToken;
                    axios.delete(`/api/orderRecommendation/sent/${recId}?session=${sToken}`)
                        .then(() => {
                            this.refreshList();
                        })
                        .catch(() => {
                            EventBus.$emit('modal-requested', this.$t('orderRecForm.unknownError'))
                        });
                }
            );
        }
    },
    computed: {
        filteredOffers()
        {
            let offers = JSON.parse(JSON.stringify(this.sentOffers));
            return offers.filter(offer => {
                if (
                    !offer.customer.number.toLowerCase().includes(this.searchText.toLowerCase())
                    && !offer.customer.company.toLowerCase().includes(this.searchText.toLowerCase())
                    && !offer.name.toLowerCase().includes(this.searchText.toLowerCase())
                ) {
                    return false;
                }
                return true;
            }).sort((first, second) => {
                let firstVal = first;
                let secondVal = second;

                //resolve sort key
                this.sort.sortColumn.split('.').forEach(index => {
                    firstVal = firstVal[index];
                    secondVal = secondVal[index];
                })

                if (firstVal < secondVal) {
                    return this.sort.sortDir === 'asc' ? -1 : 1;
                }
                if (firstVal > secondVal) {
                    return this.sort.sortDir === 'asc' ? 1 : -1;
                }
                return 0;
            });
        }
    }
}
</script>

<style scoped lang="scss">
    @import "@/FrontEnd/styles/overview";
</style>