<template>
    <table cellspacing="0" cellpadding="0">
        <tr>
            <td>ALLGEMEINE    VERKAUFS-, LIEFER- UND ZAHLUNGSBEDINGUNGEN </td>
        </tr>
        <tr>
            <td>der Firma Katadyn Deutschland    GmbH </td>
        </tr>
        <tr>
            <td></td>
        </tr>
        <tr>
            <td>Dieser Text unterliegt    deutschem Recht und soll nach deutschem Rechtsverständnis ausgelegt werden.    Er ist in deutscher und englischer Sprache ausgefertigt. Im Falle von    Abweichungen zwischen der deutschen und der englischen Fassung hat die    deutsche Fassung Vorrang.</td>
        </tr>
        <tr>
            <td></td>
        </tr>
        <tr>
            <td>§ 1 Allgemeines,    Geltungsbereich</td>
        </tr>
        <tr>
            <td>(1) Die vorliegenden    Allgemeinen Verkaufs- und Liefer- und Zahlungsbedingungen (AGB) gelten für    alle unsere Geschäftsbeziehungen mit unseren Kunden (nachfolgend auch als:    &bdquo;Käufer&ldquo; bezeichnet). Die AGB  gelten    nur, wenn der Käufer Unternehmer (§ 14 BGB), eine juristische Person des    öffentlichen Rechts oder ein öffentlich-rechtliches Sondervermögen ist.</td>
        </tr>
        <tr>
            <td>(2) Die AGB gelten    insbesondere für Verträge über den Verkauf und/oder die Lieferung beweglicher    Sachen (im Folgenden auch als: &bdquo;Ware&ldquo; bezeichnet), ohne Rücksicht darauf, ob    wir die Ware selbst herstellen oder bei Zulieferern einkaufen (§§ 433, 651    BGB). Die AGB gelten in ihrer jeweiligen Fassung als Rahmenvereinbarung auch    für künftige Verträge über den Verkauf und/oder die Lieferung beweglicher    Sachen mit demselben Käufer, ohne dass wir in jedem Einzelfall wieder auf sie    hinweisen müssten.</td>
        </tr>
        <tr>
            <td>(3) Unsere AGB gelten    ausschließlich. Abweichende, entgegenstehende oder ergänzende Allgemeine    Geschäftsbedingungen des Käufers werden nur dann und insoweit    Vertragsbestandteil, als wir ihrer Geltung ausdrücklich zugestimmt haben.    Dieses Zustimmungserfordernis gilt in jedem Fall, beispielsweise auch dann,    wenn wir in Kenntnis der Allgemeinen Geschäftsbedingungen des Käufers die    Lieferung an ihn vorbehaltlos ausführen.</td>
        </tr>
        <tr>
            <td>(4) Im Einzelfall getroffene,    individuelle Vereinbarungen mit dem Käufer (einschließlich Nebenabreden,    Ergänzungen und Änderungen) haben in jedem Fall Vorrang vor diesen AGB. Für    den Inhalt derartiger Vereinbarungen ist ein schriftlicher Vertrag bzw. unsere    schriftliche Bestätigung maßgebend.</td>
        </tr>
        <tr>
            <td>(5) Rechtserhebliche    Erklärungen und Anzeigen, die nach Vertragsschluss vom Käufer uns gegenüber    abzugeben sind (z.B. Fristsetzungen, Mängelanzeigen, Erklärung von Rücktritt    oder Minderung), bedürfen zu ihrer Wirksamkeit der Schriftform.</td>
        </tr>
        <tr>
            <td>(6) Hinweise auf die Geltung    gesetzlicher Vorschriften haben nur klarstellende Bedeutung. Auch ohne eine    derartige Klarstellung gelten daher die gesetzlichen Vorschriften, soweit sie    in diesen Allgemeinen Verkaufs- und Lieferbedingungen nicht unmittelbar    abgeändert oder ausdrücklich ausgeschlossen werden.</td>
        </tr>
        <tr>
            <td></td>
        </tr>
        <tr>
            <td>§ 2 Angebot; Vertragsschluss</td>
        </tr>
        <tr>
            <td>(1) Unsere Angebote sind    freibleibend und unverbindlich. Dies gilt auch, wenn wir dem Käufer Kataloge,    Preislisten, technische Dokumentationen (z.B. Zeichnungen, Pläne,    Berechnungen, Kalkulationen, Verweisungen auf DIN-Normen) oder sonstige    Produktbeschreibungen und Unterlagen – auch in elektronischer Form –    überlassen haben, an denen wir uns Eigentums- und Urheberrechte vorbehalten.    Diese Unterlagen dürfen nur nach unserer vorherigen Zustimmung Dritten    zugänglich gemacht werden. Die enthaltenen technischen Daten (einschließlich    Gewichts- und Maßangaben) sind sorgfältig erstellt, Irrtum vorbehalten. Das    Gleiche gilt für alle Daten unserer Verkaufsunterlagen.</td>
        </tr>
        <tr>
            <td>(2) Angaben über    Verpackungseinheiten und Verpackungsgrößen sind freibleibend und können von    uns in regelmäßigen Abständen aktualisiert werden.</td>
        </tr>
        <tr>
            <td>(3) Angaben in bei    Vertragsschluss gültigen Beschreibungen über Lieferumfang, Aussehen,    Leistungen, Maße und Gewichte etc. des Kaufgegenstandes stellen keine    Garantiezusage dar.</td>
        </tr>
        <tr>
            <td>(4) Bestellungen, die unseren    Außendienstmitarbeitern und Vertretungen erteilt werden, sind erst nach    unserer Bestätigung rechtsverbindlich.</td>
        </tr>
        <tr>
            <td>(5) Die Bestellung der Ware    durch den Käufer gilt als verbindliches Vertragsangebot. Sofern sich aus der    Bestellung nichts anderes ergibt, sind wir berechtigt, dieses Vertragsangebot    innerhalb von 2 Wochen nach seinem Zugang bei uns anzunehmen.</td>
        </tr>
        <tr>
            <td>(6) Die Annahme kann entweder    schriftlich, per Fax oder per E-Mail (z.B. durch Auftragsbestätigung) oder    durch Auslieferung der Ware an den Käufer erklärt werden.</td>
        </tr>
        <tr>
            <td></td>
        </tr>
        <tr>
            <td>§ 3 Lieferfrist, Lieferverzug</td>
        </tr>
        <tr>
            <td>(1) Die Lieferfrist wird    individuell vereinbart bzw. von uns bei Annahme der Bestellung angegeben.    Sofern die Versendung der Ware vereinbart wurde, beziehen sich Lieferfristen    und Liefertermine auf den Zeitpunkt der Übergabe an den Spediteur,    Frachtführer oder sonst mit dem Transport beauftragten Dritten. </td>
        </tr>
        <tr>
            <td>(2) Lieferfristen beginnen,    soweit nicht anders vereinbart, mit dem Datum der Auftragsbestätigung, jedoch    nicht vor der rechtzeitigen und ordnungsgemäßen Erfüllung der Verpflichtungen    des Käufers, insbesondere also nicht vor der Beibringung der vom Käufer zu    beschaffenden Unterlagen, Genehmigungen, Freigaben sowie vor Eingang einer    vereinbarten Anzahlung.</td>
        </tr>
        <tr>
            <td>(3) Wir sind zu    Teillieferungen berechtigt, wenn</td>
        </tr>
        <tr>
            <td>• die Teillieferung für den    Käufer im Rahmen des vertraglichen Bestimmungszwecks verwendbar ist,</td>
        </tr>
        <tr>
            <td>• die Lieferung der restlichen    bestellten Ware sichergestellt ist, und</td>
        </tr>
        <tr>
            <td>• dem Käufer hierdurch kein    erheblicher Mehraufwand oder zusätzliche Kosten entstehen (es sei denn, wir    erklären uns zur Übernahme dieser Kosten bereit).</td>
        </tr>
        <tr>
            <td>(4) Wir haften nicht für    Unmöglichkeit der Lieferung oder für Lieferverzögerungen, soweit diese durch    höhere Gewalt oder sonstige, zum Zeitpunkt des Vertragsschlusses nicht    vorhersehbare Ereignisse (z.B. Betriebsstörungen aller Art, unvermeidbare    Rohstoffverknappung wie bspw. Material- oder Energiebeschaffung,    Arbeitskämpfe, Streiks, Aussperrungen, Mangel an Arbeitskräften,    Schwierigkeiten bei der Beschaffung von notwendigen behördlichen    Genehmigungen, behördliche Ausnahmen oder die ausbleibende, nicht richtige    oder nicht rechtzeitige Belieferung durch Lieferanten) verursacht worden    sind, die wir nicht zu vertreten haben. Sofern solche Ereignisse uns die    Lieferung oder Leistung wesentlich erschweren oder unmöglich machen und die    Behinderung nicht nur von vorübergehender Dauer ist, sind wir zum Rücktritt    vom Vertrag berechtigt. Im Falle des Rücktritts werden wir eine bereits    erbrachte Gegenleistung des Käufers unverzüglich erstatten.</td>
        </tr>
        <tr>
            <td>(5) Bei Hindernissen    vorübergehender Dauer verlängern sich die Liefer- oder Leistungsfristen oder    verschieben sich die Liefer- oder Leistungstermine um den Zeitraum der    Behinderung zuzüglich einer angemessenen Anlauffrist.</td>
        </tr>
        <tr>
            <td>(6) Soweit dem Käufer infolge    der Verzögerung die Abnahme der Lieferung oder Leistung nicht zuzumuten ist,    kann er durch unverzügliche schriftliche Erklärung uns gegenüber vom Vertrag    zurücktreten.</td>
        </tr>
        <tr>
            <td>(7) Unsere gesetzlichen    Rücktritts- und Kündigungsrechte sowie die gesetzlichen Vorschriften über die    Abwicklung des Vertrags bei einem Ausschluss der Leistungspflicht (z.B.    Unmöglichkeit oder Unzumutbarkeit der Leistung und/oder Nacherfüllung)    bleiben unberührt. Unberührt bleiben auch die Rücktritts- und    Kündigungsrechte des Käufers gemäß § 8 dieser AGB. Geraten wir mit einer    Lieferung oder Leistung in Verzug oder wird uns eine Lieferung oder Leistung,    gleich aus welchem Grunde, unmöglich, so ist unsere Haftung auf    Schadensersatz nach Maßgabe des § 8 dieser AGB beschränkt.</td>
        </tr>
        <tr>
            <td>(8) Der Eintritt unseres    Lieferverzugs bestimmt sich nach den gesetzlichen Vorschriften. In jedem Fall    ist aber eine Mahnung durch den Käufer erforderlich.</td>
        </tr>
        <tr>
            <td></td>
        </tr>
        <tr>
            <td>§ 4 Lieferung, Gefahrübergang,    Abnahme, Annahmeverzug</td>
        </tr>
        <tr>
            <td>(1) Die Lieferung erfolgt ab    Werk oder Auslieferungslager, wo auch der Erfüllungsort ist. Auf Verlangen    und Kosten des Käufers wird die Ware an einen anderen Bestimmungsort versandt    (Versendungskauf). Soweit nicht etwas anderes vereinbart ist, sind wir berechtigt,    die Art der Versendung (insbesondere Transportunternehmen, Versandweg,    Verpackung) selbst zu bestimmen. Auf Wunsch des Käufers wird die Versendung    auf seine Kosten durch uns gegen Diebstahl, Bruch, Transport, Feuer- und    Wasserschaden sowie gegen sonstige versicherbare Risiken versichert.</td>
        </tr>
        <tr>
            <td>(2) Die Gefahr des    zufälligen Untergangs und der zufälligen Verschlechterung der Ware geht    spätestens mit der Übergabe auf den Käufer über. Beim Versendungskauf geht    jedoch die Gefahr des zufälligen Untergangs und der zufälligen    Verschlechterung der Ware sowie die Verzögerungsgefahr bereits mit    Auslieferung der Ware an den Spediteur, den Frachtführer oder der sonst zur    Ausführung der Versendung bestimmten Person oder Anstalt über. Dies gilt auch    dann, wenn frachtfreie Lieferung vereinbart ist. </td>
        </tr>
        <tr>
            <td width="487">(3)    Kommt der Käufer in Annahmeverzug, unterlässt er eine Mitwirkungshandlung    oder verzögert sich unsere Lieferung aus anderen, vom Käufer zu vertretenden    Gründen, so sind wir berechtigt, Ersatz des hieraus entstehenden Schadens    einschließlich Mehraufwendungen (z.B. Lagerkosten) zu verlangen. Hierfür    berechnen wir eine pauschale Entschädigung in Höhe von 0,5 % (in Worten: Null    Komma Fünf Prozent) des Nettopreises pro vollendeter Kalenderwoche, beginnend    mit der Lieferfrist bzw. – mangels einer Lieferfrist – mit der Mitteilung der    Versandbereitschaft der Ware, insgesamt jedoch nicht mehr als 5 % (in Worten:    Fünf Prozent) des Nettopreises, mit welcher sich der Käufer in Annahmeverzug    befindet. Der Nachweis eines höheren Schadens und unsere gesetzlichen Ansprüche    (insbesondere Ersatz von Mehraufwendungen, angemessene Entschädigung,    Kündigung) bleiben unberührt; die Pauschale ist aber auf weitergehende    Geldansprüche anzurechnen. Dem Käufer bleibt der Nachweis gestattet, dass uns    überhaupt kein oder nur ein wesentlich geringerer Schaden als vorstehende    Pauschale entstanden ist.</td>
        </tr>
        <tr>
            <td></td>
        </tr>
        <tr>
            <td>§ 5 Preise,    Zahlungsbedingungen, Rabatte, Mindermengen</td>
        </tr>
        <tr>
            <td>(1) Die angebotenen Preise    gelten nur für den jeweiligen Einzelauftrag. Angaben in Preislisten und    sonstige allgemeine Preisangaben sind freibleibend und können von uns in    regelmäßigen Abständen aktualisiert werden.</td>
        </tr>
        <tr>
            <td>(2) Erfolgen Lieferungen    oder Leistungen später als 4 Monate nach dem in der Auftragsbestätigung    vorgesehenen Termin, ist der Lieferant bei zwischenzeitlicher Änderung der    Listenpreise und/oder der Material-, Lohn- oder sonstigen Kosten berechtigt,    neue Preise zu berechnen. Die angebotenen Preise gelten nur für den    jeweiligen Einzelauftrag. Die Vereinbarung eines Festpreises bedarf einer    ausdrücklichen schriftlichen Vereinbarung. Die Vereinbarung eines Festpreises    bedarf einer ausdrücklichen schriftlichen Vereinbarung. </td>
        </tr>
        <tr>
            <td>(3) Erfüllungsort für die    Zahlung ist unser Geschäftssitz. Sofern im Einzelfall nichts anderes    vereinbart ist, gelten unsere jeweils zum Zeitpunkt des Vertragsschlusses    aktuellen Preise, und zwar ab Werk einschließlich Verladung im Werk, jedoch    ausschließlich Verpackung, Fracht, Überführung, Versicherung, Zölle zuzüglich    etwaig anfallender jeweils gültiger gesetzlicher Umsatzsteuer.</td>
        </tr>
        <tr>
            <td>(4) Beim Versendungskauf (§ 4    Absatz 1 dieser AGB) trägt der Käufer die Transportkosten ab Werk und die    Kosten einer ggf. vom Käufer gewünschten Transportversicherung. Etwaige    Zölle, Gebühren, Steuern und sonstige öffentliche Abgaben trägt der Käufer.    Transport- und alle sonstigen Verpackungen nach Maßgabe der    Verpackungsverordnung nehmen wir nicht zurück, sie werden Eigentum des    Käufers; ausgenommen sind Paletten.</td>
        </tr>
        <tr>
            <td>(5) Soweit nicht anders    vereinbart, ist der Kaufpreis sofort fällig und innerhalb von 10 Tagen ab    Rechnungsstellung und Lieferung bzw. Abnahme der Ware zu zahlen. Bei Käufern,    deren Kreditverhältnisse uns nicht bekannt sind oder bei Zweifeln an der    Zahlungsfähigkeit des Auftraggebers, erfolgt die Lieferung gegen    Vorauszahlung oder Nachnahme.</td>
        </tr>
        <tr>
            <td>(6) Mit Ablauf vorstehender    Zahlungsfrist kommt der Käufer in Verzug. Der Kaufpreis ist während des    Verzugs zum jeweils geltenden gesetzlichen Verzugszinssatz zu verzinsen. Wir    behalten uns die Geltendmachung eines weitergehenden Verzugsschadens vor. Gegenüber    Kaufleuten bleibt unser Anspruch auf den kaufmännischen Fälligkeitszins (§    353 HGB) unberührt.</td>
        </tr>
        <tr>
            <td>(7) Dem Käufer stehen    Aufrechnungs- oder Zurückbehaltungsrechte nur insoweit zu, als sein Anspruch    rechtskräftig festgestellt oder unbestritten ist. Außerdem ist der Käufer zur    Ausübung eines Zurückbehaltungsrechtes nur insoweit befugt, als sein Gegenanspruch    auf demselben Vertragsverhältnis beruht. Ein Zurückbehaltungsrecht wegen    Teilleistungen nach § 320 Absatz 2 BGB steht dem Käufer nicht zu. Bei Mängeln    der Lieferung bleibt § 7 Absatz 6 dieser AGB unberührt.</td>
        </tr>
        <tr>
            <td>(8) Wird nach Abschluss des    Vertrags erkennbar, dass unser Anspruch auf den Kaufpreis durch mangelnde    Leistungsfähigkeit des Käufers gefährdet wird (z.B. durch Antrag auf    Eröffnung eines Insolvenzverfahrens), so sind wir nach den gesetzlichen    Vorschriften zur Leistungsverweigerung und – gegebenenfalls nach Fristsetzung    – zum Rücktritt vom Vertrag berechtigt (§ 321 BGB). Bei Verträgen über die    Herstellung unvertretbarer Sachen (Einzelanfertigungen), können wir den    Rücktritt sofort erklären; die gesetzlichen Regelungen über die    Entbehrlichkeit der Fristsetzung bleiben unberührt.</td>
        </tr>
        <tr>
            <td>(9) Die Entgegennahme von    Wechseln und Schecks bedarf stets einer besonderen Vereinbarung. Die Annahme    von Wechseln und Schecks erfolgt nur erfüllungshalber. Die Kosten der    Diskontierung und der Einziehung trägt der Käufer. Diese Kosten sind sofort    fällig. Nach Annahme der Wechsel sind wir berechtigt, diese zurückzugeben,    falls deren Annahme von der Landeszentralbank verweigert wird.</td>
        </tr>
        <tr>
            <td>(10) Aktuelle    Mindestbestellmengen und Zuschläge entnehmen Sie bitte der gültigen    Preisliste. </td>
        </tr>
        <tr>
            <td>(11) Von uns in Katalogen,    Prospekten und Preislisten genannte Preise gelten für Händler im Verhältnis    zu ihren Kunden als unverbindliche Preisempfehlung.</td>
        </tr>
        <tr>
            <td></td>
        </tr>
        <tr>
            <td>§ 6 Eigentumsvorbehalt</td>
        </tr>
        <tr>
            <td>(1) Bis zur vollständigen    Bezahlung aller unserer Forderungen aus dem Kaufvertrag und einer laufenden    Geschäftsbeziehung (gesicherte Forderungen) behalten wir uns das Eigentum an    den verkauften Waren vor (Vorbehaltsware).</td>
        </tr>
        <tr>
            <td>(2) Bei vertragswidrigem    Verhalten des Käufers, insbesondere sofern er mit der Zahlung einer    Entgeltforderung in Verzug gekommen ist, sind wir berechtigt, die    Vorbehaltsware zurückzunehmen, nachdem wir den Käufer zuvor erfolglos eine    angemessene Frist zur Zahlung gesetzt haben oder eine derartige Fristsetzung    nach den gesetzlichen Vorschriften entbehrlich ist. Die für die Rücknahme    anfallenden Transportkosten trägt der Käufer. Sofern wir die Vorbehaltsware    zurücknehmen, stellt dies einen Rücktritt vom Vertrag dar. Ebenfalls einen    Rücktritt vom Vertrag stellt es dar, wenn wir die Vorbehaltsware pfänden. Von    uns zurückgenommene Vorbehaltsware dürfen wir verwerten. Der Erlös der    Verwertung wird mit denjenigen Beträgen verrechnet, die uns der Käufer schuldet,    nachdem wir einen angemessenen Betrag für die Kosten der Verwertung abgezogen    haben.</td>
        </tr>
        <tr>
            <td>(3) Der Käufer ist    verpflichtet, die Vorbehaltsware pfleglich zu behandeln. Der Käufer ist    insbesondere verpflichtet, die Vorbehaltsware auf eigene Kosten gegen Feuer-,    Wasser- und Diebstahlschäden ausreichend zum Neuwert zu versichern. Sofern    Wartungs- und Inspektionsarbeiten erforderlich sind, hat der Käufer diese auf    eigene Kosten rechtzeitig durchzuführen.</td>
        </tr>
        <tr>
            <td>(4) Der Käufer ist befugt, die    unter Eigentumsvorbehalt stehenden Waren im ordnungsgemäßen Geschäftsgang    weiter zu veräußern, solange er nicht in Zahlungsverzug ist. Die unter    Eigentumsvorbehalt stehenden Waren dürfen vor vollständiger Bezahlung der    gesicherten Forderungen weder an Dritte verpfändet noch zur Sicherheit    übereignet werden.</td>
        </tr>
        <tr>
            <td>(5) Die Entgeltforderungen    des Käufers gegenüber seinen Abnehmern aus einem Weiterverkauf der    Vorbehaltsware sowie diejenigen Forderungen des Käufers bezüglich der    Vorbehaltsware, die aus einem sonstigen Rechtsgrund gegenüber seinen    Abnehmern oder Dritten entstehen (insbesondere Forderungen aus unerlaubter    Handlung und Ansprüche auf Versicherungsleistungen) tritt uns der Käufer    bereits jetzt sicherungshalber in vollem Umfang ab. Wir nehmen diese    Abtretung an.</td>
        </tr>
        <tr>
            <td>Der Käufer darf diese an uns    abgetretenen Forderungen auf seine Rechnung im eigenen Namen für uns    einziehen, solange wir diese Ermächtigung nicht widerrufen. Unser Recht,    diese Forderungen selbst einzuziehen, wird dadurch nicht berührt; allerdings    werden wir die Forderungen nicht selbst geltend machen und die    Einzugsermächtigung nicht widerrufen, solange der Käufer seinen    Zahlungsverpflichtungen ordnungsgemäß nachkommt.</td>
        </tr>
        <tr>
            <td>Sofern sich der Käufer jedoch    vertragswidrig verhält – insbesondere sofern er mit der Zahlung einer    Entgeltforderung in Verzug gekommen ist -, können wir vom Käufer verlangen,    dass dieser uns die abgetretenen Forderungen und die jeweiligen Schuldner    bekannt gibt, den jeweiligen Schuldnern die Abtretung mitteilt und uns alle    Unterlagen aushändigt sowie allen Angaben macht, die wir zur Geltendmachung    der Forderung benötigen.</td>
        </tr>
        <tr>
            <td>Der Käufer darf diese    Forderungen auch nicht abtreten, um sie im Wege des Factoring einziehen zu    lassen, es sei denn, er verpflichtet den Factor unwiderruflich dazu, die    Gegenleistung solange unmittelbar an uns zu bewirken, als noch Forderungen    von uns gegenüber dem Käufer bestehen.</td>
        </tr>
        <tr>
            <td>(6) Eine Verarbeitung oder    Umbildung der Vorbehaltsware durch den Käufer wird immer für uns vorgenommen.    Wenn die Vorbehaltsware mit anderen Sachen verarbeitet wird, die uns nicht    gehören, so erwerben wir Miteigentum an der neuen Sache im Verhältnis des Wertes    der Vorbehaltsware (Rechnungsendbetrag zzgl. etwaig anfallender jeweils    gültiger gesetzlicher Umsatzsteuer) zu den anderen verarbeiteten Sachen im    Zeitpunkt der Verarbeitung. Im Übrigen gilt für die durch Verarbeitung    entstehende neue Sache das Gleiche wie für die Vorbehaltsware.</td>
        </tr>
        <tr>
            <td>Wird die Vorbehaltsware mit    anderen uns nicht gehörenden Sachen untrennbar verbunden oder vermischt, so    erwerben wir Miteigentum an der neuen Sache im Verhältnis des Wertes der    Vorbehaltsware (Rechnungsendbetrag zzgl. etwaig anfallender jeweils gültiger gesetzlicher    Umsatzsteuer) zu den anderen verbundenen oder vermischten Sachen im Zeitpunkt    der Verbindung oder Vermischung.</td>
        </tr>
        <tr>
            <td>Wird die Vorbehaltsware in der    Weise verbunden oder vermischt, dass die Sache des Käufers als Hauptsache    anzusehen ist, sind der Käufer und wir uns bereits jetzt einig, dass der    Käufer uns anteilsmäßig Miteigentum an dieser Sache überträgt. Wir nehmen diese    Übertragung an.</td>
        </tr>
        <tr>
            <td>Das so entstandene    Alleineigentum oder Miteigentum an einer Sache wird der Käufer für uns    unentgeltlich verwahren.</td>
        </tr>
        <tr>
            <td>(7) Bei Pfändungen der    Vorbehaltsware durch Dritte oder bei sonstigen Eingriffen Dritter muss der    Käufer auf unser Eigentum hinweisen und muss uns unverzüglich schriftlich    benachrichtigen, damit wir unsere Eigentumsrechte durchsetzen können. Sofern    der Dritte die uns in diesem Zusammenhang entstehenden gerichtlichen oder    außergerichtlichen Kosten nicht zu erstatten vermag, haftet hierfür der    Käufer.</td>
        </tr>
        <tr>
            <td>(8) Wenn der Käufer dies    verlangt, sind wir verpflichtet, die uns zustehenden Sicherheiten insoweit    freizugeben, als ihr realisierbarer Wert den Wert unserer offenen Forderungen    gegen den Käufer um mehr als 10% übersteigt. Wir dürfen dabei jedoch die freizugebenden    Sicherheiten auswählen.</td>
        </tr>
        <tr>
            <td>(9) Soweit das Recht, in    dessen Bereich sich die Ware befindet, einen Eigentumsvorbehalt nicht    zulässt, gilt § 14 Absatz 1 S. 2 bis 4 dieser AGB.</td>
        </tr>
        <tr>
            <td></td>
        </tr>
        <tr>
            <td>§ 7 Mängelansprüche des    Käufers</td>
        </tr>
        <tr>
            <td>(1) Für die Rechte des Käufers    bei Sach- und Rechtsmängeln (einschließlich Falsch- und Minderlieferung sowie    unsachgemäßer Montage oder mangelhafter Montageanleitung) gelten die    gesetzlichen Vorschriften, soweit im Nachfolgenden nichts anderes bestimmt ist.</td>
        </tr>
        <tr>
            <td>(2) Wird im Falle der    Endlieferung der Ware an einen Verbraucher (Lieferantenregress gemäß §§ 478,    479 BGB) der Käufer wegen eines Mangels der neu hergestellten Ware in    Anspruch genommen, ist der Käufer verpflichtet, uns unverzüglich hierüber zu    informieren. Der Käufer hat seine Abnehmer entsprechend zu verpflichten,    sofern diese Unternehmer sind. Wir behalten uns vor, die vom Abnehmer    gegenüber dem Käufer geltend gemachten Ansprüche im Wege des Selbsteintritts    zu erfüllen. In diesem Fall gilt die Erfüllung der Ansprüche des Abnehmers    als Erfüllung etwaiger Ansprüche des Käufers. Im Übrigen bleiben die    gesetzlichen Sondervorschriften bei Endlieferung der Ware an einen    Verbraucher (Lieferantenregress gemäß §§ 478, 479 BGB) unberührt.</td>
        </tr>
        <tr>
            <td>(3) Soweit die Beschaffenheit    nicht vereinbart wurde, ist nach der gesetzlichen Regelung zu beurteilen, ob    ein Mangel vorliegt oder nicht (§ 434 Absatz 1 Satz 2 und 3 BGB). Für    öffentliche Äußerungen des Herstellers oder sonstiger Dritter (z.B. Werbeaussagen)    übernehmen wir jedoch keine Haftung.</td>
        </tr>
        <tr>
            <td>(4) Die Mängelansprüche des    Käufers setzen voraus, dass er seinen gesetzlichen Untersuchungs- und    Rügepflichten (§§ 377, 381 HGB) nachgekommen ist. Zeigt sich bei der    Untersuchung oder später ein Mangel, so ist uns hiervon unverzüglich    schriftlich Anzeige zu machen. Als unverzüglich gilt die Anzeige, wenn sie    innerhalb von acht (8) Werktagen erfolgt, wobei zur Fristwahrung die    rechtzeitige Absendung der Anzeige genügt. Unabhängig von dieser    Untersuchungs- und Rügepflicht hat der Käufer offensichtliche Mängel    (einschließlich Falsch- und Minderlieferung) unverzüglich schriftlich    anzuzeigen, wobei auch hier zur Fristwahrung die rechtzeitige Absendung der    Anzeige genügt. Versäumt der Käufer die ordnungsgemäße Untersuchung und/oder    Mängelanzeige, ist unsere Haftung für den nicht angezeigten Mangel    ausgeschlossen.</td>
        </tr>
        <tr>
            <td>(5) Ist die gelieferte Sache    mangelhaft, können wir zunächst wählen, ob wir Nacherfüllung durch    Beseitigung des Mangels (Nachbesserung) oder durch Lieferung einer    mangelfreien Sache (Ersatzlieferung) leisten. Unser Recht, die gewählte Art    der Nacherfüllung unter den gesetzlichen Voraussetzungen zu verweigern,    bleibt unberührt.</td>
        </tr>
        <tr>
            <td>(6) Wir sind berechtigt, die    geschuldete Nacherfüllung davon abhängig zu machen, dass der Käufer den    fälligen Kaufpreis bezahlt. Der Käufer ist jedoch berechtigt, einen im    Verhältnis zum Mangel angemessenen Teil des Kaufpreises zurückzubehalten.</td>
        </tr>
        <tr>
            <td>(7) Der Käufer hat uns die    zur geschuldeten Nacherfüllung erforderliche Zeit und Gelegenheit zu geben,    insbesondere die beanstandete Ware zu Prüfungszwecken zu übergeben. Im Falle    der Ersatzlieferung hat uns der Käufer die mangelhafte Sache nach den gesetzlichen    Vorschriften zurückzugeben. Die Nacherfüllung beinhaltet weder den Ausbau der    mangelhaften Sache noch den erneuten Einbau, wenn wir ursprünglich nicht zum    Einbau verpflichtet waren.</td>
        </tr>
        <tr>
            <td>(8) Die zum Zweck der Prüfung    und Nacherfüllung erforderlichen Aufwendungen, insbesondere Transport-,    Wege-, Arbeits- und Materialkosten (nicht: Ausbau- und Einbaukosten), tragen    wir, wenn tatsächlich ein Mangel vorliegt. Stellt sich jedoch ein Mangelbeseitigungsverlangen    des Käufers als unberechtigt heraus, können wir die hieraus entstandenen    Kosten vom Käufer ersetzt verlangen. Ersetzte Teile werden unser Eigentum und    sind an uns zurückzugeben.</td>
        </tr>
        <tr>
            <td>(9) Wenn die Nacherfüllung    fehlgeschlagen ist oder eine für die Nacherfüllung vom Käufer zu setzende    angemessene Frist erfolglos abgelaufen oder nach den gesetzlichen    Vorschriften entbehrlich ist, kann der Käufer vom Kaufvertrag zurücktreten    oder den Kaufpreis mindern. Bei einem unerheblichen Mangel besteht jedoch    kein Rücktrittsrecht.</td>
        </tr>
        <tr>
            <td>(10) Ansprüche des Käufers auf    Schadensersatz bzw. Ersatz vergeblicher Aufwendungen bestehen nur nach    Maßgabe von § 8 dieser AGB und sind im Übrigen ausgeschlossen.</td>
        </tr>
        <tr>
            <td></td>
        </tr>
        <tr>
            <td>§ 8 Sonstige Haftung</td>
        </tr>
        <tr>
            <td>(1) Soweit sich aus diesen    AGB  einschließlich der nachfolgenden    Bestimmungen nichts anderes ergibt, haften wir bei einer Verletzung von    vertraglichen und außervertraglichen Pflichten nach den einschlägigen    gesetzlichen Vorschriften.</td>
        </tr>
        <tr>
            <td>(2) Auf Schadensersatz haften    wir – gleich aus welchem Rechtsgrund – bei Vorsatz und grober Fahrlässigkeit.    Bei einfacher Fahrlässigkeit haften wir nur</td>
        </tr>
        <tr>
            <td>a) für Schäden aus der    Verletzung des Lebens, des Körpers oder der Gesundheit,</td>
        </tr>
        <tr>
            <td>b) für Schäden aus der    Verletzung einer wesentlichen Vertragspflicht (Verpflichtung, deren Erfüllung    die ordnungsgemäße Durchführung des Vertrags überhaupt erst ermöglicht und    auf deren Einhaltung der Vertragspartner regelmäßig vertraut und vertrauen    darf); in diesem Fall ist unsere Haftung jedoch auf den Ersatz des    vorhersehbaren, typischerweise eintretenden Schadens begrenzt.</td>
        </tr>
        <tr>
            <td>(3) Die sich aus Absatz 2    ergebenden Haftungsbeschränkungen gelten nicht, soweit wir einen Mangel    arglistig verschwiegen oder eine Garantie für die Beschaffenheit der Ware    übernommen haben. Das gleiche gilt für Ansprüche des Käufers nach dem    Produkthaftungsgesetz.</td>
        </tr>
        <tr>
            <td>(4) Wegen einer    Pflichtverletzung, die nicht in einem Mangel besteht, kann der Käufer nur    zurücktreten oder kündigen, wenn wir die Pflichtverletzung zu vertreten    haben. Ein freies Kündigungsrecht des Käufers (insbesondere gemäß §§ 651, 649    BGB) wird ausgeschlossen. Im Übrigen gelten die gesetzlichen Voraussetzungen    und Rechtsfolgen.</td>
        </tr>
        <tr>
            <td>(5) Soweit unsere Haftung    ausgeschlossen oder beschränkt ist, gilt dies im gleichen Umfang zugunsten    unserer Organe, gesetzlichen Vertreter, Angestellten und sonstigen    Erfüllungsgehilfen.</td>
        </tr>
        <tr>
            <td></td>
        </tr>
        <tr>
            <td>§ 9 Verpflichtungen nach dem    Elektro- und Elektronikgerätegesetz (ElektroG)</td>
        </tr>
        <tr>
            <td>(1) Der Käufer übernimmt die    Verpflichtung, die gelieferte Ware nach deren Nutzungsbeendigung auf eigene    Kosten nach den gesetzlichen Vorschriften ordnungsgemäß zu entsorgen und    stellt uns von den Verpflichtungen nach § 10 Abs. 2 ElektroG – Rücknahmepflicht    der Hersteller – und damit im Zusammenhang stehender Ansprüche Dritter frei.</td>
        </tr>
        <tr>
            <td>(2) Sofern der Käufer die    Waren an gewerbliche Dritte weitergibt und diese nicht vertraglich zur    Übernahme der Entsorgung und zur Weiterverpflichtung verpflichtet, obliegt es    dem Käufer, die gelieferte Ware nach Nutzungsbeendigung auf seine Kosten    zurückzunehmen und nach den gesetzlichen Vorschriften ordnungsgemäß zu    entsorgen.</td>
        </tr>
        <tr>
            <td>(3) Unser Anspruch auf    Übernahme / Freistellung durch den Käufer verjährt nicht vor Ablauf von zwei    Jahren nach der endgültigen Beendigung der Nutzung des Gerätes. Diese Frist    beginnt frühestens mit Zugang einer schriftlichen Mitteilung des Käufers bei    uns über die Nutzungsbeendigung.</td>
        </tr>
        <tr>
            <td></td>
        </tr>
        <tr>
            <td>§ 10 Verjährung</td>
        </tr>
        <tr>
            <td>(1) Abweichend von § 438    Absatz 1 Nummer 3 BGB beträgt die allgemeine Verjährungsfrist für Ansprüche    aus Sach- und Rechtsmängeln ein Jahr ab Ablieferung. Soweit eine Abnahme    vereinbart ist, beginnt die Verjährung mit der Abnahme.</td>
        </tr>
        <tr>
            <td>(2) Unberührt bleiben    gesetzliche Sonderregelungen für dingliche Herausgabeansprüche Dritter (§ 438    Absatz 1 Nummer 1 BGB), bei Arglist des Verkäufers (§ 438 Absatz 3 BGB) und    für Ansprüche im Lieferantenregress bei Endlieferung an einen Verbraucher (§    479 BGB).</td>
        </tr>
        <tr>
            <td>(3) Die vorstehenden    Verjährungsfristen des Kaufrechts gelten auch für vertragliche und    außervertragliche Schadensersatzansprüche des Käufers, die auf einem Mangel    der Ware beruhen, es sei denn die Anwendung der regelmäßigen gesetzlichen    Verjährung (§§ 195, 199 BGB) würde im Einzelfall zu einer kürzeren Verjährung    führen. Die Verjährungsfristen des Produkthaftungsgesetzes bleiben in jedem    Fall unberührt. Ansonsten gelten für Schadensersatzansprüche des Käufers    gemäß § 8 dieser AGB ausschließlich die gesetzlichen Verjährungsfristen.</td>
        </tr>
        <tr>
            <td></td>
        </tr>
        <tr>
            <td>§ 11 Gewerbliche Schutzrechte</td>
        </tr>
        <tr>
            <td>(1) Der Käufer verpflichtet    sich, die an der gelieferten Ware und der dazugehörigen Dokumentation    bestehenden Urheber- und sonstigen geistigen Schutzrechte zu beachten.</td>
        </tr>
        <tr>
            <td>(2) Ist die von uns gelieferte    Ware nach Zeichnungen, Beschreibungen oder Mustern des Käufers angefertigt,    so übernimmt der Käufer die Gewähr dafür, dass durch unsere Herstellung und    Lieferung in der vorgesehenen Ausführung keine Urheber- und sonstigen geistigen    Schutzrechte Dritter verletzt werden.</td>
        </tr>
        <tr>
            <td></td>
        </tr>
        <tr>
            <td>§ 12 Geheimhaltung</td>
        </tr>
        <tr>
            <td>Der Käufer verpflichtet sich,    über Betriebs- und Geschäftsgeheimnisse, die ihm im Rahmen der    Geschäftsbeziehung oder des Vertrages zur Kenntnis gelangen, auch nach    Vertragsbeendigung stillschweigen zu bewahren. Die    Geheimhaltungsverpflichtung erlischt erst, wenn und soweit dieses Wissen    allgemein bekannt geworden ist oder dem Käufer bereits bei Vertragsschluss    bekannt war, ohne das eine Vertragsverletzung des Käufers hierfür ursächlich    ist.</td>
        </tr>
        <tr>
            <td></td>
        </tr>
        <tr>
            <td>§ 13 Datenschutz</td>
        </tr>
        <tr>
            <td>Im Hinblick auf    personenbezogene Daten des Käufers wahren wir die maßgeblichen gesetzlichen    Bestimmungen, insbesondere die Datenschutz-Grundverordnung (DSGVO). Die von    uns gespeicherten personenbezogenen Daten nutzen wir lediglich zu eigenen    Zwecken und geben diese nicht an außenstehende Dritte weiter, sofern hierzu    keine behördlich angeordnete Verpflichtung besteht oder der Käufer nicht    ausdrücklich seine Einwilligung gegeben hat. Diese Regelung über den Umgang    mit personenbezogenen Daten wird durch den Datenschutzhinweis sowie das    Informationsblatt für Kunden und Lieferanten konkretisiert und ergänzt.</td>
        </tr>
        <tr>
            <td></td>
        </tr>
        <tr>
            <td>§ 14 Rechtswahl, Gerichtsstand</td>
        </tr>
        <tr>
            <td>(1) Für diese AGB und alle    Rechtsbeziehungen zwischen uns und dem Käufer gilt das Recht der    Bundesrepublik Deutschland unter Ausschluss aller internationalen und    supranationalen (Vertrags-) Rechtsordnungen, insbesondere des UN-Kaufrechts    (CISG). Voraussetzungen und Wirkungen des Eigentumsvorbehalts gemäß § 6    dieser AGB unterliegen hingegen dem Recht am jeweiligen Lageort der Sache,    soweit danach die getroffene Rechtswahl zugunsten des deutschen Rechts    unzulässig oder unwirksam ist. Soweit das Recht, in dessen Bereich sich die    Ware befindet, einen Eigentumsvorbehalt nicht zulässt, können wir alle Rechte    ausüben, die wir uns an der Ware vorbehalten können. Der Käufer ist    verpflichtet, bei Maßnahmen mitzuwirken, die wir zum Schutz unseres    Eigentumsrechts oder an dessen Stelle eines anderen Sicherungsrechts an der    Ware treffen</td>
        </tr>
        <tr>
            <td>(2) Ist der Käufer Kaufmann    im Sinne des Handelsgesetzbuchs, juristische Person des öffentlichen Rechts    oder ein öffentlich-rechtliches Sondervermögen, ist ausschließlicher – auch    internationaler – Gerichtsstand für alle sich aus dem Vertragsverhältnis unmittelbar    oder mittelbar ergebenden Streitigkeiten unser Geschäftssitz in    Mörfelden-Waldorf. Wir sind jedoch auch berechtigt, Klage am allgemeinen    Gerichtsstand des Käufers zu erheben.</td>
        </tr>
        <tr>
            <td>(3) Sollte eine Bestimmung    dieser AGB oder eine später in diese aufgenommene Bestimmung ganz oder    teilweise nichtig sein oder werden oder sollte sich eine Lücke in diesen AGB    herausstellen, wird dadurch die Wirksamkeit der übrigen Bestimmungen nicht    berührt und damit § 139 BGB insgesamt abbedungen. An Stelle der nichtigen    Bestimmung oder zur Ausfüllung der Lücke ist diejenige wirksame und    durchführbare Regelung zu vereinbaren, die rechtlich und wirtschaftlich dem    am nächsten kommt, was die Parteien gewollt haben oder nach dem Sinn und    Zweck des Vertrages gewollt hätten, wenn sie diesen Punkt beim Abschluss des    Vertrags bedacht hätten. Beruht die Nichtigkeit einer Bestimmung auf einem    darin festgelegten Maß der Leistung oder der Zeit (Frist oder Termin), so ist    die Bestimmung mit einem dem ursprünglichen Maß am nächsten kommenden    rechtlich zulässigen Maß zu vereinbaren.</td>
        </tr>
    </table>

</template>

<script>
export default {
    name: "Terms"
}
</script>

<style scoped>

</style>