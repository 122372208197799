<template>
    <table cellspacing="0" cellpadding="0">
        <tr>
            <td>Katadyn    Deutschland GmbH</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td>Kontakt</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td>Büro und Lager/</td>
            <td>Katadyn Deutschland GmbH</td>
            <td>Tel.: +49 (0) 6105-45 67 89&nbsp;</td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td>Office and Warehouse</td>
            <td>Hessenring 23</td>
            <td>Fax.: +49 (0) 6105-4 58 77</td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td>&nbsp;</td>
            <td>D-64546 Mörfelden-Walldorf&nbsp;</td>
            <td>info@katadyn.de</td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td>&nbsp;</td>
            <td>Germany</td>
            <td>www.katadyn.de</td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td>Team Katadyn Deutschland GmbH</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td colspan="2">Vertriebsinnendienst/    Sales administration:</td>
            <td>order@katadyn.de</td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td>Beratung und Verkauf/ Sales:</td>
            <td>&nbsp;</td>
            <td>sales@katadyn.de</td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td>Kundendienst/ Customerservice:</td>
            <td>&nbsp;</td>
            <td>customerservice@katadyn.de</td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td>Bestellungen/ Order</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td>Email:</td>
            <td>&nbsp;</td>
            <td>order@katadyn.de</td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td>Fax:&nbsp;</td>
            <td>&nbsp;</td>
            <td>+49 (0) 6105-4 58 77</td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td>Buchhaltung/ Bookkeeping:</td>
            <td>&nbsp;</td>
            <td>finance@katadyn.de</td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td>Marketing / PR</td>
            <td>&nbsp;</td>
            <td>marketing@katadyn.de</td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td>Salessupport/Bilder</td>
            <td>&nbsp;</td>
            <td>www.katadyn-sales.de</td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td>Vertriebsleitung/</td>
            <td>Henning Schlenker</td>
            <td>henning.schlenker@katadyn.de</td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td>Vice President Sales</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td>Geschäftsführung/</td>
            <td>Stefanie Dietrich</td>
            <td>stefanie.dietrich@katadyn.de</td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td>President</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
        </tr>
    </table>

</template>

<script>
export default {
name: "Contact"
}
</script>

<style scoped lang="scss">
    table tr{
        background: $main-bg-color !important;
    }
</style>