<template>
    <div class="msg-wrapper">
        <h2>{{ $t('noSeasonFound.title') }}</h2>
        <p>{{ $t('noSeasonFound.desc') }}</p>
        <button class="primary" @click="logout">{{ $t('noSeasonFound.logout') }}</button>
    </div>
</template>

<script>
export default {
    name: "NoOrderSeasonFound",
    methods: {
        logout() {
            this.$store.dispatch('logout');
        }
    }
}
</script>

<style scoped lang="scss">
    @import "@/FrontEnd/styles/input";
    @import "@/FrontEnd/styles/layout";
    .msg-wrapper {
        @include center-middle-content;
        padding: $regular-padding;
    }
</style>