<template>
  <div id="app">
      <router-view></router-view>
      <modal-overlay></modal-overlay>
  </div>
</template>

<script>
import ModalOverlay from "@/FrontEnd/components/ModalOverlay";
export default {
    name: 'App',
    components: {ModalOverlay}
}
</script>

<style lang="scss">
    @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');
    html,body{
        height: 100%;
        margin:0;
    }
    #app {
        height: 100%;
        font-family: $main-font-family;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: $main-font-color;
    }
</style>
