<template>
    <tr>
        <th>{{ $t('article.nr') }}</th>
        <!--<th>{{ $t('article.lang-cluster') }}</th>-->
        <th>{{ $t('article.description') }}</th>
        <th>{{ $t('article.info') }}</th>
        <th>{{ $t('article.masterpack-amount') }}</th>
        <th>{{ $t('article.moq') }}</th>
        <th>{{ $t('article.gtin') }}</th>
        <th>{{ $t('article.net-price') }}</th>
        <th>{{ $t('article.recommended-list-price') }}</th>
        <th
            v-for="date in shipmentDates.dates"
            :key="date.id"
            class="shipment"
        >
            <input
                v-if="singleSelection"
                v-model="areAllSelected"
                type="checkbox"
                :indeterminate.prop="areSomeSelected"
            >
            <span v-else-if="shipmentDates.getAmountOfDates() > 1">
                <datepicker
                    class="shipment-date"
                    :language="de"
                    monday-first
                    v-model="date.date"
                    :disabled-dates="disabledDates"
                    :format="formatDate"
                ></datepicker>

            </span>
            <span v-else>{{ $t('article.amount') }}</span>
        </th>
        <th v-if="shipmentDates.getAmountOfDates() > 1">{{ $t('article.total-amount') }}</th>
        <th v-if="!singleSelection">{{ $t('article.total-net-price') }}</th>
    </tr>
</template>

<script>
import {de} from 'vuejs-datepicker/dist/locale';
import Datepicker from 'vuejs-datepicker';
import ShipmentDates from "@/FrontEnd/models/ShipmentDates";

export default {
    name: "OrderSheetShipmentHeader",
    components: {Datepicker},
    props: {
        shipmentDates: {
            type: ShipmentDates
        },
        singleSelection: {
            type: Boolean,
            default: false
        },
        firstShipmentDate: {
            type: Date
        },
        lastShipmentDate: {
            type: Date
        },
        setColNum: {
            type: Function
        },
        brands: {
            type: Array
        }
    },
    data () {
        return {
            de
        };
    },
    created() {
        // Calculate column number and tell parent via callback
        this.calcColNum();
    },
    computed: {
        disabledDates() {
            return {
                to: this.firstShipmentDate,
                from: this.lastShipmentDate
            }
        },
        areAllSelected: {
            get() {
                for (let brand of this.brands) {
                    for (let article of brand.articles) {
                        if (this.shipmentDates.getFirst().getAmount(article.id) < 1) {
                            return false;
                        }
                    }
                }
                return true;
            },
            set(selected) {
                for (let brand of this.brands) {
                    for (let article of brand.articles) {
                        this.shipmentDates.getFirst().setAmount(article.id, selected ? 1 : 0);
                    }
                }
            }
        },
        areSomeSelected() {
            let foundSome = false;
            let missedSome = false;
            for (let brand of this.brands) {
                for (let article of brand.articles) {
                    if (this.shipmentDates.getFirst().getAmount(article.id) < 1) {
                        missedSome = true;
                    }
                    if (this.shipmentDates.getFirst().getAmount(article.id) > 0) {
                        foundSome = true;
                    }
                }
            }
            return (foundSome && missedSome);
        }
    },
    methods: {
        calcColNum() {
            // Number of fixed columns
            let colNum = 9;
            // plus one column for each shipment date
            colNum += this.shipmentDates.getAmountOfDates();
            if (this.shipmentDates.getAmountOfDates() > 1) {
                // if there is more than one shipment date, we have an additional column for total amount
                colNum += 1;
            }
            if (this.singleSelection) {
                // if this is a single selection, we don't have a total net price
                colNum -= 1;
            }
            // tell parent
            this.setColNum(colNum);
        },
        formatDate(date) {
            return this.$d(date, 'short');
        }
    }
}
</script>

<style scoped lang="scss">
    @import "@/FrontEnd/styles/input";

    .shipment-date /deep/ input {
        @include input-style;
    }
</style>