<template>
    <th @click="handleSelect">
        <slot></slot>
        <fa-icon icon="chevron-down" v-if="isAscending"></fa-icon>
        <fa-icon icon="chevron-up" v-if="isDescending"></fa-icon>
    </th>
</template>

<script>
export default {
    name: "SortableHeaderCell",
    props: {
        value: {
            type: Object
        },
        column: {
            type: String
        },
        defaultSortDir: {
            type: String,
            default: 'asc'
        }
    },
    computed: {
        isAscending() {
            return this.value.sortColumn === this.column && this.value.sortDir === 'asc';
        },
        isDescending() {
            return this.value.sortColumn === this.column && this.value.sortDir === 'desc';
        }
    },
    methods: {
        handleSelect() {
            let sort = {
                sortColumn: this.column,
                sortDir: this.defaultSortDir
            };
            if (this.value.sortColumn === this.column) {
                sort.sortDir = 'asc';
                if (this.value.sortDir === 'asc') {
                    sort.sortDir = 'desc';
                }
            }
            this.$emit('input', sort);
        }
    }
}
</script>

<style scoped lang="scss">
    th {
        cursor: pointer;
        user-select: none;
    }
</style>