<template>
    <page-with-navigation>
        <div class="form-wrapper">
            <button class="slim" @click="toggleBaseData">
                <span v-if="baseDataHidden">{{ $t('orderSeasonForm.show') }}</span>
                <span v-else>{{ $t('orderSeasonForm.hide') }}</span>
            </button>
            <fieldset class="order-season-form form" v-show="!baseDataHidden">
                <div :class="{'error-input': nameMissing}">
                    <label :for="'name-'+_uid">{{ $t('orderSeasonForm.name') }}</label>
                    <input :id="'name-'+_uid" type="text" v-model="name" />
                    <span v-if="nameMissing" class="error-msg">{{ $t('orderSeasonForm.nameMissing') }}</span>
                </div>
                <div>
                    <label :for="'end-date-'+_uid">{{ $t('orderSeasonForm.endDate') }}</label>
                    <datepicker
                        :language="de"
                        monday-first
                        v-model="endDate"
                        :id="'end-date-'+_uid"
                        :format="formatDate"
                    ></datepicker>
                </div>
            </fieldset>
            <advance-order-deadline-editor v-show="!baseDataHidden" v-model="deadlines"></advance-order-deadline-editor>
            <fieldset class="order-season-discounts" v-show="!baseDataHidden">
                <div>
                    <label :for="'volume-discounts-'+_uid">{{ $t('orderSeasonForm.volumeDiscount') }}</label>
                    <input :id="'volume-discounts-'+_uid" type="checkbox" v-model="volumeDiscount">
                </div>
                <base-discount v-model="baseDiscount"></base-discount>
                <discount-scale v-model="discountScale"></discount-scale>
            </fieldset>

            <order-sheet
                :class="{'article-selection':true, 'top-space':baseDataHidden}"
                ref="articles"
                single-selection
            ></order-sheet>


            <div class="order-season-action">
                <div class="customer-link" v-if="seasonId">
                    <label>{{ $t('orderSeasonForm.link') }}</label>
                    <input type="text" readonly v-model="orderSeasonLink">
                </div>
                <button class="primary" @click="saveSeason" v-if="isNew">
                    {{ $t('orderSeasonForm.save') }}
                    <loading-spinner v-if="saving"></loading-spinner>
                    <fa-icon v-else icon="save"></fa-icon>
                </button>
                <button class="primary" @click="updateSeason" v-else>
                    {{ $t('orderSeasonForm.update') }}
                    <loading-spinner v-if="saving"></loading-spinner>
                    <fa-icon v-else icon="save"></fa-icon>
                </button>
            </div>
        </div>
    </page-with-navigation>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import PageWithNavigation from "@/FrontEnd/components/PageWithNavigation";
import {de} from 'vuejs-datepicker/dist/locale';
import axios from "axios";
import LoadingSpinner from "@/FrontEnd/components/LoadingSpinner";
import OrderSheet from "@/FrontEnd/components/OrderSheet";
import EventBus from "@/FrontEnd/eventbus";
import DiscountScale from "@/FrontEnd/components/DiscountScale";
import BaseDiscount from "@/FrontEnd/components/BaseDiscount";
import AdvanceOrderDeadlineEditor from "@/FrontEnd/components/AdvanceOrderDeadlineEditor";

export default {
    name: "OrderSeasonEditor",
    components: {
        AdvanceOrderDeadlineEditor,
        BaseDiscount,
        DiscountScale,
        OrderSheet,
        LoadingSpinner,
        PageWithNavigation,
        Datepicker
    },
    props: {
        seasonId: String
    },
    data () {
        let today = new Date();
        let in3Months = new Date();
        in3Months.setMonth(today.getMonth() + 3);

        return {
            name: '',
            startDate: today,
            endDate: in3Months,
            de,
            saving: false,
            nameMissing: false,
            volumeDiscount: false,
            baseDiscount: null,
            discountScale: [],
            baseDataHidden: false,
            deadlines: [
                {date: new Date(), startDate: new Date()},
                {date: new Date(), startDate: new Date()},
                {date: new Date(), startDate: new Date()},
                {date: new Date(), startDate: new Date()}
            ]
        }
    },
    created() {
        if (this.seasonId) {
            this.loadSeason();
        }
    },
    computed: {
        isNew() {
            return !this.seasonId;
        },
        orderSeasonLink() {
            return window.location.origin + `/advanceOrder/${this.seasonId}`;
        }
    },
    methods: {
        loadSeason() {
            let sToken = this.$store.state.sessionToken;
            axios.get(`/api/orderSeason/${this.seasonId}?session=${sToken}`)
                .then(response => {
                    this.name = response.data.name;
                    let selection = this.$refs.articles.getShipments().getFirst();
                    for (let brand of response.data.articles) {
                        for (let article of brand.articles) {
                            selection.setAmount(article.id, 1);
                        }
                    }
                    this.deadlines = response.data.deadlines.map(
                        (deadline) => {
                            deadline.date = Date.parse(deadline.date);
                            deadline.startDate = Date.parse(deadline.startDate);
                            return deadline;
                        }
                    );
                    this.endDate = Date.parse(response.data.endDate);
                    this.baseDiscount = response.data.baseDiscount;
                    this.discountScale = response.data.staggeredDiscounts;
                    if (response.data.volumeDiscounts && response.data.volumeDiscounts.length > 0) {
                        this.volumeDiscount = true;
                    }
                })
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        this.$store.dispatch('logout', {name: this.$route.name});
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        saveSeason() {
            if (!this.validate()) {
                return;
            }
            let sToken = this.$store.state.sessionToken;
            this.saving = true;
            axios.post(`/api/orderSeason?session=${sToken}`, this.preparePayload())
                .then((response) => {
                    EventBus.$emit(
                        'modal-requested',
                        this.$t('orderSeasonForm.saveSuccess'),
                        () => {
                            this.$router.push({ name: 'edit-season', params: {seasonId: response.data.id}});
                        }
                    );
                })
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        this.$store.dispatch('logout', {name: this.$route.name});
                        return;
                    }
                    EventBus.$emit(
                        'modal-requested',
                        this.$t('orderRecForm.unknownError')
                    );
                })
                .finally(() => {
                    this.saving = false;
                });
        },
        updateSeason() {
            if (!this.validate()) {
                return;
            }
            let sToken = this.$store.state.sessionToken;
            this.saving = true;
            axios.post(`/api/orderSeason/${this.seasonId}?session=${sToken}`, this.preparePayload())
                .then(() => {
                    EventBus.$emit(
                        'modal-requested',
                        this.$t('orderSeasonForm.updateSuccess')
                    );
                })
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        this.$store.dispatch('logout', {name: this.$route.name});
                        return;
                    }
                    EventBus.$emit(
                        'modal-requested',
                        this.$t('orderRecForm.unknownError')
                    );
                })
                .finally(() => {
                    this.saving = false;
                });
        },
        preparePayload() {
            let deadlines = JSON.parse(JSON.stringify(this.deadlines));
            deadlines.map((deadline) => {
                deadline.date = (
                    (deadline.date instanceof Date) ? deadline.date : new Date(deadline.date)
                ).toISOString();
                deadline.startDate = (
                    (deadline.startDate instanceof Date) ? deadline.startDate : new Date(deadline.startDate)
                ).toISOString();
                return deadline;
            });

            return {
                name: this.name,
                deadlines,
                endDate: (this.endDate instanceof Date) ? this.endDate.toISOString() : new Date(this.endDate).toISOString(),
                articles: this.$refs.articles.getSelectedArticles(),
                volumeDiscounts: this.volumeDiscount,
                baseDiscount: this.baseDiscount,
                staggeredDiscounts: this.discountScale
            }
        },
        validate() {
            this.nameMissing = false;
            if (!this.name) {
                this.nameMissing = true;
                return false;
            }
            if (this.$refs.articles.getSelectedArticles().length < 1) {
                EventBus.$emit(
                    'modal-requested',
                    this.$t('orderSeasonForm.articlesMissing')
                );
                return false;
            }
            return true;
        },
        toggleBaseData() {
            this.baseDataHidden = !this.baseDataHidden;
        },
        formatDate(date) {
            return this.$d(date, 'short');
        }
    }
}
</script>

<style scoped lang="scss">
    @import "@/FrontEnd/styles/input";
    @import "@/FrontEnd/styles/layout";

    /deep/ input:not([type=checkbox]) {
        width: 100%;
        @include input-style;
    }

    .top-space {
        margin-top: 4rem;
    }

    .article-selection {
        flex: 1;
        overflow-y: scroll;
        padding: 0 .4rem;
    }

    .form-wrapper {
        @include content-wrapper-inside;
        position: relative;

        &>button {
            position: absolute;
            top: 0;
            right: 0;
            margin: $regular-padding;
        }
    }
    .order-season-discounts {
        border: none;
        box-sizing: border-box;
        width: 100%;
        max-width: 60rem;
        margin: 0 auto;
        &>* {
            margin-bottom: $regular-padding;
        }
    }
    .order-season-action {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        &>* {
            margin: 0 $regular-padding;
        }
        @include footer-action-bar;
    }
    .customer-link {
        width: 50%;
        max-width: 40rem;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
</style>