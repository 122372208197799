<template>
    <div>
        <input type="text"
               :placeholder="placeholder"
               :value="value"
               @input="e => $emit('input', e.target.value)"
               class="filter"
        />
        <fa-icon class="filter-unset-action" icon="times" v-if="value" @click="unsetFilter"></fa-icon>
        <fa-icon class="filter-search-icon" icon="search" v-else></fa-icon>
    </div>
</template>

<script>
export default {
    name: "FilterInput",
    props: {
        value: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String
        }
    },
    methods: {
        unsetFilter() {
            this.$emit('input', '');
        }
    }
}
</script>

<style scoped lang="scss">
    @import "@/FrontEnd/styles/input";
    div {
        position: relative;
    }
    input {
        @include input-style;
        @extend .slim;
        &.filter {
            padding-right: 1.8rem;
            box-sizing: border-box;
            width: 100%;
        }
    }
    .filter-unset-action{
        cursor: pointer;
    }
    .filter-search-icon, .filter-unset-action {
        position: absolute;
        top: .4rem;
        right: .4rem;
    }

</style>