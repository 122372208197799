<template>
    <nav class="main-nav">
        <ul class="nav-endpoints">
            <li v-if="canSeeRecEditor"><router-link :to="{name:'list-recommendations'}">{{ $t('mainNav.orderRecTemplateOverview') }}</router-link></li>

            <li v-if="canSeeSeasonEditor"><router-link :to="{name:'list-seasons'}">{{ $t('mainNav.orderSeasonOverview') }}</router-link></li>

        </ul>
        <div class="user-actions">
            <span>{{ $store.getters.userName }}</span>
            <ul>
                <li><a href="#" @click="logout">Logout <fa-icon icon="sign-out-alt"></fa-icon></a></li>
            </ul>
        </div>
    </nav>
</template>

<script>
export default {
    name: "MainNavigation",
    computed: {
        canSeeSeasonEditor() {
            return this.$store.getters.isAdmin;
        },
        canSeeRecEditor() {
            return this.$store.getters.isAdmin || this.$store.getters.isAgent;
        }
    },
    methods: {
        logout() {
            this.$store.dispatch('logout');
        }
    }
}
</script>

<style scoped lang="scss">
    @import "@/FrontEnd/styles/navigation";

    nav.main-nav {
        @include nav-style;
    }
    ul.nav-endpoints {
        text-align: left;
        justify-content: flex-start;
    }
    .user-actions {
        text-align: right;
        justify-content: flex-end;
    }
</style>