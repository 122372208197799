<template>
    <div class="overview-wrapper">
        <h2>{{ $t('orderRecOverview.title') }}</h2>
        <fieldset>
            <filter-input v-model="searchText" :placeholder="$t('orderRecOverview.filterTemplates')"></filter-input>
        </fieldset>
        <div class="table-wrapper">
        <table>
            <thead>
            <tr>
                <sortable-header-cell column="name" v-model="sort">
                    {{ $t('orderRecOverview.name') }}
                </sortable-header-cell>
                <sortable-header-cell column="lastModified" v-model="sort" default-sort-dir="desc">
                    {{ $t('orderRecOverview.modified') }}
                </sortable-header-cell>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr class="loading" v-if="loading">
                <td colspan="2"><loading-spinner></loading-spinner></td>
            </tr>
            <tr class="empty-state" v-if="!loading && filteredRecommendations.length < 1 && searchText">
                <td colspan="2">{{ $t('article.emptyFilterResult') }}</td>
            </tr>
            <tr class="data" v-for="recommendation in filteredRecommendations" :key="recommendation.id">
                <td>
                    <router-link :to="{ name: 'recommend', params: {offerId: recommendation.id}}">
                        {{ recommendation.name }}
                    </router-link>
                </td>
                <td>
                    {{ formatDate(recommendation.lastModified) }}
                </td>
                <td class="actions">
                    <button class="slim" @click="() => deleteRecommendation(recommendation.id, recommendation.name)">
                        <fa-icon icon="trash-alt"></fa-icon>
                    </button>
                </td>
            </tr>
            </tbody>
        </table>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import LoadingSpinner from "@/FrontEnd/components/LoadingSpinner";
import FilterInput from "@/FrontEnd/components/FilterInput";
import SortableHeaderCell from "@/FrontEnd/components/SortableHeaderCell";
import EventBus from "@/FrontEnd/eventbus";

export default {
    name: "OrderRecommendationList",
    components: {SortableHeaderCell, FilterInput, LoadingSpinner},
    data () {
        return {
            recommendations: [],
            loading: false,
            searchText: '',
            sort: {
                sortColumn: 'lastModified',
                sortDir: 'desc'
            }
        };
    },
    created() {
        this.refreshList();
    },
    methods: {
        refreshList() {
            this.recommendations = [];
            let sToken = this.$store.state.sessionToken;
            this.loading = true;
            axios.get(`/api/orderRecommendation?session=${sToken}`)
                .then(response => (this.recommendations = response.data))
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        this.$store.dispatch('logout', {name: this.$route.name});
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        formatDate(date) {
            date = Date.parse(date);
            return new Intl.DateTimeFormat('de-DE', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: 'numeric',
                minute: 'numeric',
            }).format(date);
        },
        deleteRecommendation(recId, name) {
            EventBus.$emit(
                'confirm-requested',
                this.$t('orderRecOverview.deleteTemplateConfirm', {name}),
                () => {
                    let sToken = this.$store.state.sessionToken;
                    axios.delete(`/api/orderRecommendation/${recId}?session=${sToken}`)
                        .then(() => {
                            this.refreshList();
                        })
                        .catch(() => {
                            EventBus.$emit('modal-requested', this.$t('orderRecForm.unknownError'))
                        });
                }
            );
        }
    },
    computed: {
        filteredRecommendations()
        {
            let recs = JSON.parse(JSON.stringify(this.recommendations));
            return recs.filter(rec => {
                if (!rec.name.toLowerCase().includes(this.searchText.toLowerCase())) {
                    return false;
                }
                return true;
            }).sort((first, second) => {
                if (first[this.sort.sortColumn] < second[this.sort.sortColumn]) {
                    return this.sort.sortDir === 'asc' ? -1 : 1;
                }
                if (first[this.sort.sortColumn] > second[this.sort.sortColumn]) {
                    return this.sort.sortDir === 'asc' ? 1 : -1;
                }
                return 0;
            });
        }
    }
}
</script>

<style scoped lang="scss">
    @import "@/FrontEnd/styles/overview";
    table {
        overflow: scroll;
    }
</style>