const numberFormats = {
    'de-DE': {
        currency: {
            style: 'currency',
            currency: 'EUR',
            currencyDisplay: 'symbol'
        }
    }
}

export default numberFormats;