import Login from "@/FrontEnd/pages/Login";
import OrderRecommendationEditor from "@/FrontEnd/pages/OrderRecommendationEditor";
import VueRouter from "vue-router";
import Vue from 'vue';
import {adminNavGuard, rootHubNavGuard, userNavGuard} from "@/FrontEnd/store";
import OrderRecommendationOverview from "@/FrontEnd/pages/OrderRecommendationOverview";
import OrderSeasonOverview from "@/FrontEnd/pages/OrderSeasonOverview";
import OrderSeasonEditor from "@/FrontEnd/pages/OrderSeasonEditor";
import AdvanceOrderForm from "@/FrontEnd/pages/AdvanceOrderForm";
import SentRecommendationEditor from "@/FrontEnd/pages/SentRecommendationEditor";
import NoOrderSeasonFound from "@/FrontEnd/pages/NoOrderSeasonFound";
import EventBus from "@/FrontEnd/eventbus";

Vue.use(VueRouter);

const routes = [
    {path: '/', beforeEnter: rootHubNavGuard, name: 'root'},
    {path: '/noSeason', component: NoOrderSeasonFound, beforeEnter: userNavGuard, name: 'no-season'},
    {path: '/login', component: Login, name: 'login'},
    {path: '/templateEditor/:offerId?', props: true, component: OrderRecommendationEditor, beforeEnter: adminNavGuard, name: 'recommend'},
    {path: '/sentOrderEditor/:offerId', props: true, component: SentRecommendationEditor, beforeEnter: adminNavGuard, name: 'edit-recommendation'},
    {path: '/orderOfferList', component: OrderRecommendationOverview, beforeEnter: adminNavGuard, name: 'list-recommendations'},
    {path: '/orderSeasonEditor/:seasonId?', props: true, component: OrderSeasonEditor, beforeEnter: adminNavGuard, name: 'edit-season'},
    {path: '/orderSeasonList', component: OrderSeasonOverview, beforeEnter: adminNavGuard, name: 'list-seasons'},
    {path: '/advanceOrder/:seasonId', props: true, component: AdvanceOrderForm, beforeEnter: userNavGuard, name: 'advance-order'},

    {path: '*', redirect: '/'},
];
const router = new VueRouter({
    routes,
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            let hashVal = decodeURIComponent(to.hash).substring(1);
            EventBus.$emit('router-hash-changed', hashVal);
            return { selector: hashVal }
        } else if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 }
        }
    }
});

export default router;