<template>
    <button @click="getPDFExport">
        {{ $t('orderRecForm.exportPDF') }}
        <fa-icon icon="file-pdf" v-if="!isExporting"></fa-icon>
        <loading-spinner v-else></loading-spinner>
    </button>
</template>

<script>
import axios from "axios";
import LoadingSpinner from "@/FrontEnd/components/LoadingSpinner";
import EventBus from "@/FrontEnd/eventbus";
import PriceGroupSelect from "@/FrontEnd/components/PriceGroupSelect";

export default {
    name: "PdfExport",
    components: {LoadingSpinner},
    props: {
        getPayload: {
            type: Function
        },
        target: {
            type: String
        },
        requestPriceGroup: {
            type: Boolean,
            default: false
        }
    },
    beforeMount() {
        window.addEventListener("beforeunload", this.preventNavigation);
    },
    beforeDestroy() {
        window.removeEventListener("beforeunload", this.preventNavigation);
    },
    data() {
        return {
            isExporting: false
        }
    },
    methods: {
        preventNavigation(event) {
            if (!this.isExporting) return;
            event.preventDefault();
            event.returnValue = "";
        },
        getPDFExport()
        {
            if (this.requestPriceGroup) {
                EventBus.$emit('modal-component-requested', PriceGroupSelect, (pricegroup) => {
                    this.requestPDF(pricegroup);
                });
                return;
            }
            this.requestPDF();
        },
        requestPDF(pricegroup) {
            let sToken = this.$store.state.sessionToken;
            let url = `${this.target}?session=${sToken}`;
            if (pricegroup) {
                url += '&priceGroup=' + pricegroup;
            }

            this.isExporting = true;
            axios.post(
                url,
                this.getPayload(),
                {responseType: 'blob'}
            )
                .then(response => {
                    let link = document.createElement('a');

                    link.href = window.URL.createObjectURL(new Blob([response.data]));
                    link.target = '_blank';
                    link.download = 'export.pdf';
                    link.click();
                })
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        this.$store.dispatch('logout', {name: this.$route.name});
                    }
                })
                .finally(() => {
                    this.isExporting = false;
                });
        }

    }
}
</script>

<style scoped>

</style>