<template>
    <header class="title-wrapper">
        <div class="title-logo"><img :src="require('@/FrontEnd/assets/logo-katadyn-group_350.png')"></div>
        <div>
            <slot></slot>
        </div>
        <div></div>
    </header>
</template>

<script>
export default {
name: "AdvanceOrderTitle"
}
</script>

<style scoped lang="scss">
    .title-wrapper {
        display: flex;
        flex-direction: row;
        margin-bottom: $regular-padding;

        &>* {
            width: 33%;
        }
    }
    .title-logo {
        text-align: left;
    }
</style>