<template>
    <tfoot>
    <tr v-if="hiddenAmountHint">
        <td class="hint" :colspan="calculateColSpan()">
            {{ $t('article.amountHidden') }}
            <a href="#" @click.prevent="$emit('reset-filter-and-show-amounts')">
                {{ $t('article.showHiddenAmounts') }}
            </a>
        </td>
    </tr>
    <tr v-if="allowCustomOrderNumber">
        <th colspan="8" class="customer-order-numbers">{{ $t('article.customerOrderNumber') }}</th>
        <td v-for="date in shipmentDates.dates" :key="date.id" class="customer-order-numbers">
            <input
                type="text"
                class="slim"
                :placeholder="$t('article.customerOrderNumberLabel')"
                :title="$t('article.customerOrderNumberTitle', {date: $d(date.date, 'short')})"
                v-model="date.orderNumber"
            >
        </td>
        <td colspan="2" class="customer-order-numbers"></td>
    </tr>
    <tr>
        <td colspan="8" class="total-amount">
            <span class="price-notice">{{ $t('article.priceNotice') }}</span>
        </td>
        <td
            v-for="date in shipmentDates.dates"
            :key="date.id"
            class="total-amount number">
            <span v-if="shipmentDates.getAmountOfDates() > 1">
                {{ $n(date.getTotalNetPrice(brands), 'currency') }}
            </span>
        </td>
        <td class="total-amount number" :colspan="2">
            <span v-if="showTotals">
                {{ $t('article.sheetTotalNetPrice') }} {{ $n(totalNetPrice, 'currency') }}
            </span>
        </td>
    </tr>
    </tfoot>
</template>

<script>
import ShipmentDates from "@/FrontEnd/models/ShipmentDates";

export default {
    name: "OrderSheetFooter",
    props: {
        shipmentDates: ShipmentDates,
        showTotals: Boolean,
        hiddenAmountHint: Boolean,
        brands: Array,
        allowCustomOrderNumber: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        totalNetPrice() {
            return this.shipmentDates.getTotalNetPrice(this.brands);
        }
    },
    methods: {
        calculateColSpan() {
            return 9 + this.shipmentDates.getAmountOfDates() + (this.shipmentDates.getAmountOfDates() > 1 ? 1 : 0);
        },
    }
}
</script>

<style scoped lang="scss">
    @import "@/FrontEnd/styles/table";
    @import "@/FrontEnd/styles/input";

    tfoot td, tfoot th {
        background-color: $main-bg-color;

        & input[type=text] {
            width: 7.2rem;
        }

        &.total-amount{
            position: -webkit-sticky;
            position: sticky;
            bottom: 0;
            height: 2.8rem;
            box-sizing: border-box;

            & .price-notice {
                float: left;
            }
        }
        &.hint, &.customer-order-numbers{
            position: -webkit-sticky;
            position: sticky;
            bottom: 2.8rem;
            &.hint {
                color: crimson;
            }
            &.customer-order-numbers {
                text-align: right;
            }
        }
    }
</style>