import Vue from 'vue';

class Shipment {
    constructor(id, date) {
        this.id = id;
        this.articleAmounts = {};
        this.date = date;
        this.orderNumber = '';
    }
    setOrderNumber(nr) {
        Vue.set(this, 'orderNumber', nr);
    }
    getAmount(articleId) {
        if(!(articleId in this.articleAmounts)) {
            return 0;
        }
        return this.articleAmounts[articleId];
    }
    setAmount(articleId, amount) {
        Vue.set(this.articleAmounts, articleId, amount);
    }
    getArticlesWithAmount() {
        let articles = [];
        for (let articleId in this.articleAmounts) {
            if (this.articleAmounts[articleId] > 0) {
                articles.push(articleId);
            }
        }
        return articles;
    }
    serialize()
    {
        let isoDate = '';
        if (this.date instanceof Date) {
            isoDate = this.date.toISOString();
        } else {
            isoDate = (new Date(this.date)).toISOString();
        }
        return {
            date: isoDate,
            articleAmounts: this.articleAmounts,
            customerOrderNumber: this.orderNumber
        }
    }

    getBrandTotal(articlesByBrand, brandId) {
        return JSON.parse(JSON.stringify(articlesByBrand))
            .filter((brand) => brand.id === brandId)
            .reduce((flatArticles, brand) => flatArticles.concat(brand.articles), [])
            .map((article) => this.getAmount(article.id) * article.netPrice)
            .reduce((price, sum) => sum + price, 0);
    }

    getTotalNetPrice(articlesByBrand) {
        let total = 0;
        for (let brand of articlesByBrand) {
            for (let article of brand.articles) {
                total += (this.getAmount(article.id) * article.netPrice);
            }
        }
        return total;
    }
}

export default Shipment;