const dateTimeFormats = {
    'de': {
        short: {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        }
    }
};

export default dateTimeFormats;