<template>
    <table>
        <thead>
            <tr>
                <th>{{ $t('advOrderDeadLine.deadline') }}</th>
                <th>{{ $t('orderSeasonForm.startDate') }}</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(deadline, index) in deadlines" :key="index">
                <td>
                    <datepicker
                        class="slim"
                        :language="de"
                        monday-first
                        v-model="deadline.date"
                        :id="'deadline-' + index"
                        :format="formatDate"
                    ></datepicker>
                </td>
                <td>
                    <datepicker
                        class="slim"
                        :language="de"
                        monday-first
                        v-model="deadline.startDate"
                        :id="'start-date-' + index"
                        :format="formatDate"
                    ></datepicker>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import {de} from 'vuejs-datepicker/dist/locale';

export default {
    name: "AdvanceOrderDeadlineEditor",
    components: { Datepicker },
    data() {
        return {
            de
        };
    },
    props: {
        value: Array
    },
    computed: {
        deadlines: {
            get() {
                return this.value;
            },
            set(deadlines) {
                this.$emit('input', deadlines);
            }
        }
    },
    methods: {
        formatDate(date) {
            return this.$d(date, 'short');
        }
    }
}
</script>

<style scoped lang="scss">
    @import "@/FrontEnd/styles/input";
    .vdp-datepicker {
        &.slim /deep/ input {
            @include slim-style;
        }
    }
    table {
        max-width: 60rem;
        & tr {
            background-color: $main-bg-color;
        }
    }
</style>