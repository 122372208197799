<template>
    <tr :class="{ data: true, 'active-input': isActive }">
        <!--<td><img v-if="article.imgPath" :src="articleImg(article.imgPath)"/></td>-->
        <td class="number art-number">{{ article.nr }}</td>
        <!--<td>{{ article.langCluster }}</td>-->
        <td>
            <a :href="articleUrl(article.id)" tabindex="-1">{{ article.name }}</a>
        </td>
        <td>{{ article.additionalInfo }}</td>
        <td class="number">{{ article.masterPackSize }}</td>
        <td class="number"></td> <!--moq-->
        <td class="number gtin">{{ article.gtin }}</td>
        <td class="number currency">{{ $n(article.netPrice, 'currency') }}</td>
        <td class="number currency">{{ $n(article.recommendedListPrice, 'currency') }}</td>
        <td v-if="singleSelection">
            <input type="checkbox" v-model="isSelected">
        </td>
        <td v-else class="shipment-amount" v-for="date in shipmentDates.dates" :key="date.id">
            <order-sheet-article-shipment-amount
                :article-id="article.id.toString()"
                :shipment="date"
                :disabled="disabled"
                :step="article.amountStep"
                :min="article.minAmount"
                @focus="isActive = true"
                @blur="isActive = false"
            >
            </order-sheet-article-shipment-amount>
        </td>
        <td v-if="shipmentDates.getAmountOfDates() > 1" class="number">{{ totalAmount }}</td>
        <td v-if="singleSelection === false" class="number currency">{{ $n(totalNetPrice, 'currency') }}</td>
    </tr>
</template>

<script>
import ShipmentDates from "@/FrontEnd/models/ShipmentDates";
import OrderSheetArticleShipmentAmount from "@/FrontEnd/components/OrderSheetArticleShipmentAmount";
import config from "@/FrontEnd/config";

export default {
    name: "OrderSheetArticleRow",
    components: {OrderSheetArticleShipmentAmount},
    data() {
        return {
            amounts: {},
            isActive: false
        }
    },
    props: {
        article: {
            type: Object
        },
        shipmentDates: {
            type: ShipmentDates
        },
        singleSelection: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        totalAmount() {
            let amount = 0;
            this.shipmentDates.getAllDates().forEach((date) => {
                amount += date.getAmount(this.article.id);
            });
            return amount;
        },
        totalNetPrice() {
            return this.totalAmount * this.article.netPrice;
        },
        isSelected: {
            get() {
                return this.shipmentDates.getArticleAmount(this.article.id) > 0;
            },
            set(selected) {
                if (selected) {
                    this.shipmentDates.getFirst().setAmount(this.article.id, 1);
                    return;
                }
                this.shipmentDates.getFirst().setAmount(this.article.id, 0);
            }
        }
    },
    methods: {
        articleUrl(articleId) {
            return config.shopUrl + `detail/index/sArticle/${articleId}`;
        },
        articleImg(imgPath) {
            return config.shopUrl + imgPath;
        }
    }
}
</script>

<style scoped lang="scss">
    .art-number {
        min-width: 2.8rem;
        max-width: 7vw;
    }
    .shipment-amount {
        min-width: 5rem;
        max-width: 10vw;
    }
    .currency {
        min-width: 5.2rem;
        max-width: 14vw;
    }
    .gtin {
        min-width: 8rem;
        max-width: 12vw;
    }
</style>