<template>
    <div>
        <p>Bitte wählen sie eine Preisgruppe für den Export</p>
        <select v-model="selection">
            <option value="EH">Einzelhändler</option>
            <option value="GH">Großhändler</option>
            <option value="D">Distributor</option>
            <option value="WS">Wholesale</option>
            <option value="KKS">Kleen Kanteen Spezial</option>
            <option value="UVN">UVP Netto</option>
            <option value="SUG">SUG Retail</option>
        </select>
    </div>
</template>

<script>
export default {
    name: "PriceGroupSelect",
    props: {
        value: String
    },
    computed: {
        selection: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import "@/FrontEnd/styles/input";
</style>