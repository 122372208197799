<template>
    <span class="wrapper"><span class="loader"></span></span>
</template>

<script>
export default {
    name: "LoadingSpinner"
}
</script>

<style scoped>
.wrapper {
    display: inline-block;
    width: 1.25em;
    height: 1em;
    overflow: hidden;
}
.loader,
.loader:before,
.loader:after {
    line-height: .5em;
    display: block;
    background: currentColor;
    -webkit-animation: load1 1s infinite ease-in-out;
    animation: load1 1s infinite ease-in-out;
    width: .25em;
    height: .5em;
}
.loader {
    color: currentColor;
    text-indent: -9999em;
    margin: .5em auto;
    position: relative;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}
.loader:before,
.loader:after {
    position: absolute;
    top: 0;
    content: '';
}
.loader:before {
    left: -.45em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}
.loader:after {
    left: .45em;
}
@-webkit-keyframes load1 {
    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: .2em;
    }
    40% {
        box-shadow: 0 -.3em;
        height: .5em;
    }
}
@keyframes load1 {
    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: .2em;
    }
    40% {
        box-shadow: 0 -.3em;
        height: .5em;
    }
}

</style>