import Vue from 'vue'
import Vuex from "vuex";
import router from "@/FrontEnd/router";

Vue.use(Vuex);

const ADMIN_TYPE = 'localAdmin';
const AGENT_TYPE = 'salesAgent';

const store = new Vuex.Store({
    state: {
        sessionToken: null,
        authRedirect: null,
        user: null
    },
    mutations: {
        login (state, {token, user}) {
            state.sessionToken = token;
            state.user = user;
            localStorage.setItem('session', state.sessionToken);
            localStorage.setItem('user', JSON.stringify(state.user));
        },
        logout (state) {
            state.sessionToken = null;
            localStorage.removeItem('session');
        },
        setRedirect (state, redirect) {
            state.authRedirect = redirect;
        },
        init (state) {
            // Look for data in local storage
            if (localStorage.getItem('session')) {
                state.sessionToken = localStorage.getItem('session');
            }
            if (localStorage.getItem('user')) {
                state.user = JSON.parse(localStorage.getItem('user'));
            }

            // If data is incomplete, reset all
            if (!state.sessionToken || !state.user) {
                state.sessionToken = null;
                state.user = null;
            }
        }
    },
    getters: {
        loggedIn: state => {
            return state.sessionToken !== null;
        },
        userName: state => {
            return state.user.name;
        },
        isAdmin: state => {
            return state.user && state.user.type === ADMIN_TYPE;
        },
        isAgent: state => {
            return state.user && state.user.type === AGENT_TYPE;
        }
    },
    actions: {
        logout({commit, dispatch}, ref) {
            commit('logout');
            dispatch('redirectToLogin', ref);
        },
        login({commit, state}, data) {
            commit('login', data);
            router.push(state.authRedirect ? state.authRedirect : {name: 'root'}).catch(()=>{});
            // reset auth redirect for next run
            commit('setRedirect', null);
        },
        redirectToLogin({commit}, ref) {
            commit('setRedirect', ref);
            router.push({ name: 'login'});
        }
    }
});

export function adminNavGuard(to, from, next) {
    if (!store.getters.loggedIn) {
        //Save target for redirect if neither login nor root
        if (to.name !== 'login' && to.name !== 'root') {
            store.commit('setRedirect', to);
        }
        next({ name: 'login' });
        return;
    }
    if (!store.getters.isAgent && !store.getters.isAdmin) {
        next({ name: 'no-season' });
        return;
    }
    next();
}

export function userNavGuard(to, from, next) {
    if (!store.getters.loggedIn) {
        //Save target for redirect if neither login nor root
        if (to.name !== 'login' && to.name !== 'root') {
            store.commit('setRedirect', to);
        }
        next({ name: 'login' });
        return;
    }
    if (store.getters.isAgent || store.getters.isAdmin) {
        next({ name: 'list-recommendations' });
        return;
    }
    next();
}

export function rootHubNavGuard(to, from, next) {
    if (!store.getters.loggedIn) {
        next({ name: 'login' });
        return;
    }
    if (store.getters.isAgent || store.getters.isAdmin) {
        next({ name: 'list-recommendations' });
        return;
    }
    next({ name: 'no-season' })
}

export default store;