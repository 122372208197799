<template>
    <form class="login-wrapper">
        <h2>{{ $t('login.title') }}</h2>
        <span class="error-msg" v-if="credentialsWrong">{{ $t('login.error') }}</span>
        <span class="error-msg" v-if="unknownError">{{ $t('login.unknownError') }}</span>
        <fieldset class="login-form">
            <label :for="'name_'+_uid">{{ $t('login.name') }}</label>
            <input :id="'name_'+_uid" type="text" :disabled="processing" v-model="email"/>
            <label :for="'pass_'+_uid">{{ $t('login.pass') }}</label>
            <input :id="'pass_'+_uid" type="password" :disabled="processing" v-model="password"/>
            <div class="login-action">
                <button type="submit" class="primary" @click.prevent="login" :disabled="processing">
                    {{ $t('login.submit') }}
                    <loading-spinner v-if="processing"></loading-spinner>
                </button>
            </div>
        </fieldset>
    </form>
</template>

<script>
import axios from "axios";
import LoadingSpinner from "@/FrontEnd/components/LoadingSpinner";

export default {
    name: "Login",
    components: {LoadingSpinner},
    data () {
        return {
            email: null,
            password: null,
            processing: false,
            credentialsWrong: false,
            unknownError: false
        }
    },
    created() {
        if (this.$store.getters.loggedIn) {
            this.$router.push({name: 'list-recommendations'});
        }
    },
    methods: {
        login() {
            if (!this.email || !this.password) {
                return;
            }

            this.credentialsWrong = false;
            this.unknownError = false;
            this.processing = true;
            axios.post('/api/login', {
                email: this.email,
                password: this.password
            })
                .then((response) => {
                    this.$store.dispatch('login', {
                        token: response.headers['x-session-token'],
                        user: response.data
                    });
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        this.credentialsWrong = true;
                        return;
                    }
                    this.unknownError = true;
                })
                .finally(() => (this.processing = false));
        }
    }
}
</script>

<style scoped lang="scss">
    @import "@/FrontEnd/styles/input";
    @import "@/FrontEnd/styles/layout";

    .login-wrapper {
        @include center-middle-content;
    }
    .login-form {
        border: none;
        display: grid;
        grid-template-rows: 25% 25% 25% 25%;
        grid-template-columns: auto auto auto;
        row-gap: $slim-padding;

        & label {
            align-self: center;
        }
        & input {
            grid-column-start: 2;
            grid-column-end: 4;
        }
        & .login-action {
            text-align: right;
            grid-column-start: 1;
            grid-column-end: 4;
            padding-top: $medium-padding;
            & button {
                margin: 0;
            }
        }
    }
</style>