import Shipment from "@/FrontEnd/models/Shipment";
import Vue from 'vue'

const FIRST_ID = 1;

class ShipmentDates {

    constructor() {
        this.dates = {};
        this.nextId = FIRST_ID;
    }

    getAmountOfDates() {
        let length = Object.keys(this.dates).length;
        if (isNaN(length)) {
            length = 0;
        }
        return length;
    }

    getFirst() {
        return this.dates[FIRST_ID];
    }

    addDate(date) {
        let shipment = new Shipment(this.nextId, date);
        Vue.set(this.dates, this.nextId, shipment);
        this.nextId++;
        return shipment;
    }

    getAllDates() {
        return Object.values(this.dates);
    }

    hasArticleAmount(articleId) {
        return this.getArticleAmount(articleId) > 0;
    }

    getArticleAmount(articleId) {
        let totalAmount = 0;
        for (let date of this.getAllDates()) {
            totalAmount += date.getAmount(articleId);
        }
        return totalAmount;
    }

    getTotalNetPrice(articlesByBrand) {
        let total = 0;
        for (let brand of articlesByBrand) {
            for (let article of brand.articles) {
                total += (this.getArticleAmount(article.id) * article.netPrice);
            }
        }
        return total;
    }

    getGroupTotal(articles, groupIds) {
        return articles
            .filter((article) => groupIds.includes(article.id))
            .map((article) => this.getArticleAmount(article.id) * article.netPrice)
            .reduce((price, sum) => sum + price, 0);
    }

    getBrandTotal(articlesByBrand, brandId) {
        let totalAmount = 0;
        for (let date of this.getAllDates()) {
            totalAmount += date.getBrandTotal(articlesByBrand, brandId);
        }
        return totalAmount;
    }

    serialize() {
        return {
            shipments: Object.values(this.dates).map((date) => date.serialize())
        }
    }
}

export default ShipmentDates;